<div>
  <form class="payment-positions-request" [formGroup]="form" appFocusFormError>
    <div
      class="payment-positions-request__flex-area payment-positions-request__flex-area--left"
    >
      <div
        class="payment-positions-request__flex-content payment-positions-request__flex-content--left"
      >
        <div class="title-xxl payment-positions-request__title">
          {{'payment_request.positions_title' | translate}}
        </div>
        <div class="title-xl payment-positions-request__subtitle">
          {{'payment_request.positions_subtitle' | translate}}
        </div>

        <div
          class="payment-positions-request__mobile payment-positions-request__small-mobile"
        >
          <app-card
            class="payment-positions-request__image--mobile"
            [padding]="'none'"
          >
            <div *ngIf="svgXML" [innerHTML]="svgXML"></div>
          </app-card>
        </div>

        <div
          class="payment-positions-request__mobile-background payment-positions-request__small-mobile-padding"
        >
          <div
            class="payment-positions-request__small-mobile payment-positions-request__position-explanation"
          >
            <ng-template
              [ngTemplateOutlet]="positionsExplanation"
            ></ng-template>
          </div>

          <app-card [padding]="'none'">
            <div
              *ngIf="svgXML"
              class="payment-positions-request__image payment-positions-request__desktop"
              [innerHTML]="svgXML"
            ></div>

            <div class="p20 pb15">
              <div
                class="payment-positions-request__desktop payment-positions-request__mobile"
              >
                <ng-template
                  [ngTemplateOutlet]="positionsExplanation"
                ></ng-template>
                <hr />
              </div>
              <div class="title-m">
                {{'payment_request.positions_overview_l' | translate}}
              </div>
              <div>
                <app-form-field
                  formArrayName="positionIds"
                  *ngFor="let position of positionsFormArray.controls; index as i"
                >
                  <div class="payment-positions-request__flex-between mt15">
                    <div
                      class="payment-positions-request__position-information"
                    >
                      <app-check
                        [squared]="true"
                        [formControlName]="i"
                      ></app-check>
                      <div>{{positions[i].subject}}</div>
                    </div>
                    <div class="payment-positions-request__position-price">
                      {{positions[i].amount | currency: currency }}
                    </div>
                  </div>
                </app-form-field>
              </div>
              <hr />
              <div class="payment-positions-request__sum">
                <div>{{'payment_request.sum_l' | translate}}</div>
                <div>{{ priceSum | currency: currency }}</div>
              </div>
            </div>
          </app-card>
        </div>
      </div>
    </div>
    <div
      class="payment-positions-request__flex-area payment-positions-request__flex-area--right"
    >
      <div
        class="payment-positions-request__flex-content payment-positions-request__flex-content--right payment-positions-request__small-mobile-padding"
      >
        <div
          class="title-xl payment-positions-request__subtitle payment-positions-request__desktop"
        >
          {{'payment_request.payment_details' | translate}}
        </div>
        <div class="m15">
          {{'payment_request.payment_details_text_1_m' | translate}} {{priceSum
          | currency: currency }} {{'payment_request.payment_details_text_2_m' |
          translate}}
        </div>

        <form [formGroup]="form">
          <div class="mb20">
            <app-radio-group [isUnselectable]="false" formControlName="type">
              <div class="payment-positions-request__payment-options">
                <app-card
                  *ngIf="showSEPA"
                  class="payment-positions-request__payment-option"
                >
                  <div
                    class="payment-positions-request__payment-option-content"
                  >
                    <app-radio-button
                      [value]="'SEPA'"
                      class="payment-positions-request__radio-button"
                    >
                      <app-image
                        [defaultSrc]="'/assets/images/logos/sepa.svg'"
                        class="payment-positions-request__radio-button-image"
                      ></app-image>
                    </app-radio-button>
                  </div>
                </app-card>

                <app-card
                  *ngIf="showPayPal"
                  class="payment-positions-request__payment-option"
                >
                  <div
                    class="payment-positions-request__payment-option-content"
                  >
                    <app-radio-button
                      [value]="'PAYPAL'"
                      class="payment-positions-request__radio-button"
                    >
                      <app-image
                        [defaultSrc]="'/assets/images/logos/paypal.svg'"
                        class="payment-positions-request__radio-button-image"
                      ></app-image>
                    </app-radio-button>
                  </div>
                </app-card>
              </div>
            </app-radio-group>
          </div>

          <app-card *ngIf="showBankDetails">
            <div class="title-m mb25">
              {{'payment_request.bank_details_l' | translate }}
            </div>
            <div>
              <div class="mb30">
                <app-form-field
                  [label]="'payment_request.name_l' | translate"
                  [labelBelowInput]="true"
                >
                  <input
                    appInput
                    type="text"
                    [placeholder]="'payment_request.first_and_last_name_placeholder' | translate"
                    [required]="true"
                    formControlName="fullName"
                    class="form-control"
                  />
                </app-form-field>
              </div>
              <div class="mb40">
                <app-form-field
                  [label]="'payment_request.iban_l' | translate"
                  [labelBelowInput]="true"
                >
                  <input
                    appInput
                    type="text"
                    [placeholder]="'payment_request.iban_placeholder' | translate"
                    [required]="true"
                    formControlName="iban"
                    class="form-control"
                  />
                </app-form-field>
              </div>
              <div class="mb15" formGroupName="address">
                <app-address-form
                  [formGroup]="addressForm"
                  [showRegion]="false"
                  [showDistricts]="false"
                  [label]="'payment_request.address_l' | translate"
                  [showLabel]="false"
                  [showBottomLabel]="true"
                  [required]="true"
                >
                </app-address-form>
              </div>
            </div>
          </app-card>
        </form>
        <app-card class="mt-3">
          <p
            class="p-2 mb-0"
            [innerHTML]="'data_privacy.payment_request_privacy_description_l' | translate"
          ></p>
        </app-card>
        <div class="payment-positions-request__button">
          <app-button [type]="'light-bordered'" (clickEvent)="onAlreadyPaid()"
            >{{'payment_request.already_paid_a' | translate }}</app-button
          >
        </div>
        <div class="payment-positions-request__button">
          <app-button
            [buttonType]="'submit'"
            [disabled]="form.invalid"
            (clickEvent)="onSend()"
            >{{'payment_request.send_a' | translate}}</app-button
          >
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #positionsExplanation>
  <div class="payment-positions-request__bold">
    {{'payment_request.pay_positions_to_m' | translate}} {{customerName}}
  </div>
</ng-template>
