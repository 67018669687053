import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-iban-modal',
  templateUrl: './confirm-iban-modal.component.html',
  styleUrls: ['./confirm-iban-modal.component.scss']
})
export class ConfirmIbanModalComponent {
  public iban: string;

  constructor(private ngbActiveModal: NgbActiveModal) {}

  public cancel() {
    this.ngbActiveModal.dismiss();
  }

  public apply() {
    this.ngbActiveModal.close();
  }
}
