<div
  *ngIf="propertyMatch || isApplyPage; else loadingTemplate"
  class="property-expose container mb20 pb15 pl0 pr0"
  [ngClass]="{
    'mt0': isApplyPage
  }"
>
  <div class="property-expose__header">
    <div
      class="property-expose__top-menu d-flex align-items-center ios-padding-top"
      [ngClass]="{
        'justify-content-between': !isApplyPage,
        'justify-content-end': isApplyPage
      }"
    >
      <app-button
        *ngIf="!isApplyPage"
        [class]="'property-expose__back-button'"
        [type]="'context-menu'"
        [iconLeft]="'arrow-left'"
        [elevation]="1"
        (clickEvent)="back()"
      >
      </app-button>
      <app-button
        *ngIf="isApplyPage && property.applyLink"
        [class]="'property-expose__share-button'"
        [type]="'context-menu'"
        [iconLeft]="'share'"
        [elevation]="1"
        (clickEvent)="onShareProperty(property.applyLink)"
      >
      </app-button>
    </div>
  </div>
  <div
    [class.no-header-image]="!property?.data?.attachments || property?.data?.attachments?.length === 0"
  >
    <app-property-card-tenant
      [propertyMatch]="propertyMatch"
      [property]="property"
      [pageView]="true"
      [enableImageGallery]="true"
      [isApplyPage]="isApplyPage"
    >
    </app-property-card-tenant>
  </div>
  <div class="property-expose__body pb-3">
    <hr class="property-expose__divider mt0 mb0" />

    <div
      *ngIf="!isApplyPage && hasDocuments"
      class="property-expose__documents mt30"
    >
      <div class="title-m mb8">{{ 'general.documents_l' | translate }}</div>
      <div class="row d-flex">
        <div class="col-12">
          <app-attachments-list-v2>
            <div class="d-flex row col-12">
              <div class="col-12 col-md-6 mb10 pl0 pr5" *ngIf="floorPlan">
                <app-attachment-item-v2
                  [attachment]="floorPlan"
                  [isDocument]="true"
                  [showRemove]="false"
                  [directDownload]="true"
                  (download)="onDownloadDocument(floorPlan)"
                >
                </app-attachment-item-v2>
              </div>
              <div
                class="col-12 col-md-6 mb10 pl0 pr5"
                *ngFor="let attachment of getOtherDocuments(property?.data?.documents)"
              >
                <app-attachment-item-v2
                  [attachment]="attachment"
                  [isDocument]="true"
                  [showRemove]="false"
                  [directDownload]="true"
                  (download)="onDownloadDocument(attachment)"
                >
                </app-attachment-item-v2>
              </div>
            </div>
          </app-attachments-list-v2>
        </div>
      </div>
    </div>

    <div
      class="property-expose__description mt30"
      *ngIf="hasAnyDescription"
      [class.property-expose__description--collapsed]="!descriptionExpanded"
      (click)="showMore()"
    >
      <div>
        <div *ngIf="getObjectDescription" class="col-12 col-md-9 p0">
          <h2 class="title-m">
            {{ 'general.property_description_l' | translate }}
          </h2>
          <p class="m0">{{ getObjectDescription }}</p>
        </div>
        <div *ngIf="getFurnishingDescription" class="mt15 col-12 col-md-9 p0">
          <h2 class="title-m">
            {{ 'general.property_description.furnishing_l' | translate }}
          </h2>
          <p class="m0">{{ getFurnishingDescription }}</p>
        </div>
        <div *ngIf="getObjectLocationText" class="mt15 col-12 col-md-9 p0">
          <h2 class="title-m">
            {{ 'general.property_description.location_l' | translate }}
          </h2>
          <p class="m0">{{ getObjectLocationText }}</p>
        </div>
        <div *ngIf="getObjectMiscellaneousText" class="mt15 col-12 col-md-9 p0">
          <h2 class="title-m">
            {{ 'general.property_description.miscellaneous_l' | translate }}
          </h2>
          <p class="m0">{{ getObjectMiscellaneousText }}</p>
        </div>
      </div>
    </div>
    <app-button
      [type]="'link'"
      (clickEvent)="showMore()"
      *ngIf="!descriptionExpanded && hasAnyDescription"
    >
      {{ 'general.show_more_l' | translate }}
    </app-button>

    <div class="property-expose__details card-columns mt30">
      <div class="card">
        <h2 class="title-m">{{ 'general.costs_l' | translate }}</h2>
        <div>
          <ng-container
            *ngIf="!isSales(property?.marketingType); else salesCostsTemplate"
          >
            <app-cost-details-cell
              [translationKey]="'general.rent_l'"
              [property]="property?.data?.basePrice"
              [showPlusVat]="isCommercial(property) && property?.data?.commercialData?.vatNotIncludedInPrice"
            >
              <svg-icon
                src="/assets/images/icons/icon-money.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
            </app-cost-details-cell>
            <app-cost-details-cell
              *ngIf="isCommercial(property) && isNotNullAndUndefined(property?.data?.commercialData?.pricePerSquareMeter) && !isSales(property?.marketingType)"
              [translationKey]="'general.price_per_square_meter_l'"
              [property]="property?.data?.commercialData?.pricePerSquareMeter"
            >
              <svg-icon
                src="/assets/images/icons/icon-money.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
            </app-cost-details-cell>

            <!-- Courtage -->
            <app-cost-details-cell
              *ngIf="isNotNullAndUndefined(property?.data?.courtageData?.courtage) && !property?.data?.courtageData?.courtageInPercent && isCommercial(property)"
              [translationKey]="'general.courtage_l'"
              [property]="property?.data?.courtageData?.courtage"
              [additionalText]="property?.data?.courtageData?.plusVAT ? 'courtage.plus_vat.included' : ''"
            >
              <svg-icon
                src="/assets/images/icons/icon-additional-costs.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color circle path"
              ></svg-icon>
            </app-cost-details-cell>

            <dl
              *ngIf="property?.data?.courtageData?.courtageInPercent && isCommercial(property)"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-money.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'general.courtage_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                <span>{{ property?.data?.courtageData?.courtage }}%</span>
                <span class="ms-1" *ngIf="property?.data?.courtageData?.plusVAT"
                  >{{'courtage.plus_vat.included' | translate}}</span
                >
              </dd>
            </dl>
            <dl
              *ngIf="property?.data?.courtageData?.text && isCommercial(property)"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-money.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'general.courtage_info_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                <span>{{ property?.data?.courtageData?.text }}</span>
              </dd>
            </dl>
            <!-- Courtage End -->

            <app-cost-details-cell
              *ngIf="!isGarage(property)"
              [translationKey]="'general.additional_costs_l'"
              [property]="property?.data?.serviceCharge"
              [showPlusVat]="isCommercial(property) && property?.data?.commercialData?.vatNotIncludedInPrice"
            >
              <svg-icon
                src="/assets/images/icons/icon-additional-costs.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color circle path"
              ></svg-icon>
            </app-cost-details-cell>
            <app-cost-details-cell
              [translationKey]="'general.other_costs_l'"
              [property]="property?.data?.otherCosts"
              [showPlusVat]="isCommercial(property) && property?.data?.commercialData?.vatNotIncludedInPrice"
            >
              <svg-icon
                src="/assets/images/icons/icon-additional-costs.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color circle path"
              ></svg-icon>
            </app-cost-details-cell>
            <app-cost-details-cell
              *ngIf="isFlat(property) || isCommercial(property)"
              [translationKey]="'general.deposit_l'"
              [property]="property?.data?.bailment"
            >
              <svg-icon
                src="/assets/images/icons/icon-coins.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
            </app-cost-details-cell>
            <app-cost-details-cell
              *ngIf="isNotNullAndUndefined(property?.entryPrice) && !property?.settings?.disableEntranceFee"
              [translationKey]="'general.entry_price_l'"
              [property]="property?.entryPrice"
            >
              <svg-icon
                src="/assets/images/icons/icon-entry-ticket.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
            </app-cost-details-cell>
            <app-cost-details-cell
              *ngIf="isFlat(property) || isCommercial(property)"
              [translationKey]="'general.heating_costs_l'"
              [property]="property?.data?.heatingCost"
              [additionalText]="property?.data?.heatingCostIncluded? 'general.included_to_additionCosts_l' : '' "
              [showPlusVat]="isCommercial(property) && property?.data?.commercialData?.vatNotIncludedInPrice"
            >
              <svg-icon
                src="/assets/images/icons/icon-fire.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
            </app-cost-details-cell>
            <app-cost-details-cell
              [translationKey]="'general.total_cost_l'"
              [property]="property?.data?.totalRentGross"
              [showPlusVat]="isCommercial(property) && property?.data?.commercialData?.vatNotIncludedInPrice"
            >
              <svg-icon
                src="/assets/images/icons/icon-moneybag.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
            </app-cost-details-cell>
          </ng-container>
          <ng-template #salesCostsTemplate>
            <app-cost-details-cell
              [translationKey]="'general.purchase_price_l'"
              [property]="property?.data?.salesData?.price"
              [showPlusVat]="isCommercial(property) && property?.data?.commercialData?.vatNotIncludedInPrice"
            >
              <svg-icon
                src="/assets/images/icons/icon-money.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
            </app-cost-details-cell>
            <app-cost-details-cell
              *ngIf="isNotNullAndUndefined(property?.data?.courtageData?.courtage) && !property?.data?.courtageData?.courtageInPercent"
              [translationKey]="'general.courtage_l'"
              [property]="property?.data?.courtageData?.courtage"
              [additionalText]="property?.data?.courtageData?.plusVAT ? 'courtage.plus_vat.included' : ''"
            >
              <svg-icon
                src="/assets/images/icons/icon-additional-costs.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color circle path"
              ></svg-icon>
            </app-cost-details-cell>
            <dl
              *ngIf="property?.data?.courtageData?.courtageInPercent"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-money.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'general.courtage_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data d-flex flex-column"
              >
                <span>{{ property?.data?.courtageData?.courtage }}%</span>
              </dd>
            </dl>
            <dl
              *ngIf="property?.data?.courtageData?.text"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-money.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'general.courtage_info_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                <span>{{ property?.data?.courtageData?.text }}</span>
              </dd>
            </dl>
            <app-cost-details-cell
              *ngIf="isNotNullAndUndefined(property?.data?.salesData?.serviceCharge)"
              [translationKey]="'property.living_price.l'"
              [property]="property?.data?.salesData?.serviceCharge"
              [showPlusVat]="isCommercial(property) && property?.data?.commercialData?.vatNotIncludedInPrice"
            >
              <svg-icon
                src="/assets/images/icons/icon-additional-costs.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color circle path"
              ></svg-icon>
            </app-cost-details-cell>
          </ng-template>

          <app-cost-details-cell
            *ngIf="(isFlat(property) || isCommercial(property)) && (property?.data?.totalParkingPrice || property?.data?.totalParkingPurchasePrice)"
            [translationKey]="'general.parking_costs_l'"
            [property]="property?.data?.totalParkingPrice || property?.data?.totalParkingPurchasePrice"
          >
            <svg-icon
              src="/assets/images/icons/icon-garage.svg"
              [applyClass]="true"
              [svgStyle]="{'width.px': 15}"
              class="primary-text-svg-color rect line polyline path"
            ></svg-icon>
          </app-cost-details-cell>
        </div>
      </div>

      <div
        *ngIf="isFlat(property) || isCommercial(property)"
        class="card"
        [ngClass]="{'u-hide': !showEnergyDetailsList}"
      >
        <h2 class="title-m">{{ 'general.property_energy_l' | translate }}</h2>
        <div #energyDetailsList>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.heater)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-fire.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_heater_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ 'HEATERFIRINGTYPES_' + property?.data?.heater | translate }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.energyCertificate?.creationDate)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-calendar.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_energy_cert_year_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ 'CERTIFICATECREATIONDATES_' +
              property?.data?.energyCertificate?.creationDate | translate }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.energyCertificate?.energyCertificateType)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-document.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_energy_cert_type_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ 'ENERGYCERTIFICATETYPES_' +
              property?.data?.energyCertificate?.energyCertificateType |
              translate }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(getEnergyEfficiencyClass)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-energy-efficiency-class.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_energy_class_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ getEnergyEfficiencyClass | translate }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.energyCertificate?.primaryEnergyProvider)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-electricity.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_primary_energy_provider_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ 'HEATERTYPES_' +
              property?.data?.energyCertificate?.primaryEnergyProvider |
              translate }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(getEnergyConsumption)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-energy-requirement.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_energy_consumption_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ getEnergyConsumption }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.energyCertificate?.primaryEnergyConsumption)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-primary-energy-demand.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_primary_energy_consumption_l' | translate
                }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.energyCertificate?.primaryEnergyConsumption }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.energyCertificate?.austrianEnergyCertificate?.dateOfExpiry)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-calendar.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_energy_date_of_expiry_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{
              property?.data?.energyCertificate?.austrianEnergyCertificate?.dateOfExpiry
              | appDateTime }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.energyCertificate?.austrianEnergyCertificate?.heatingEnergyDemand)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-energy-requirement.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_energy_heating_energy_demand_l' |
                translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{
              property?.data?.energyCertificate?.austrianEnergyCertificate?.heatingEnergyDemand
              }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.energyCertificate?.austrianEnergyCertificate?.heatingEnergyDemandClass)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-energy-efficiency-class.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_energy_heating_energy_demand_class_l' |
                translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{
              property?.data?.energyCertificate?.austrianEnergyCertificate?.heatingEnergyDemandClass
              | appEnergyClass }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.energyCertificate?.austrianEnergyCertificate?.overallEnergyEfficiencyFactor)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-primary-energy-demand.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_energy_efficiency_factor_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{
              property?.data?.energyCertificate?.austrianEnergyCertificate?.overallEnergyEfficiencyFactor
              }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.energyCertificate?.austrianEnergyCertificate?.overallEnergyEfficiencyFactorClass)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-energy-efficiency-class.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_energy_efficiency_factor_class_l' |
                translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{
              property?.data?.energyCertificate?.austrianEnergyCertificate?.overallEnergyEfficiencyFactorClass
              | appEnergyClass }}
            </dd>
          </dl>
        </div>
      </div>

      <div class="card">
        <h2 class="title-m">{{ 'general.property_details_l' | translate }}</h2>
        <div>
          <dl
            *ngIf="!isCommercial(property); else commercialTypeRef"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-object.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_objectType_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ 'FLATTYPES_' + property?.data?.objectType | translate }}
            </dd>
          </dl>
          <ng-template #commercialTypeRef>
            <dl class="property-expose__details-row">
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-object.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'property.commercial_type_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                {{ 'commercial_type.' +
                property?.data?.commercialData?.commercialType?.toLowerCase() +
                '_l' | translate }}
              </dd>
            </dl>
            <dl class="property-expose__details-row">
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-object.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'property.commercial_sub_type_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                {{ 'commercial_sub_type.' +
                property?.data?.commercialData?.commercialSubType?.toLowerCase()
                + '_l' | translate }}
              </dd>
            </dl>
          </ng-template>
          <dl
            *ngIf="(isFlat(property) || isCommercial(property)) && isNotNullAndUndefined(property?.data?.buildingCondition)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-paint-roller.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.condition_of_apartment_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ 'BUILDINGCONDITIONTYPES_' + property?.data?.buildingCondition |
              translate }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.furnishingType)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-chair.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.furnishing_type_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ ('furnishing.' + property?.data?.furnishingType) | translate }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.floor)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-story.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.floor_number_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.floor | appFloor }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.ground)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-floor.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.ground_type_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ 'GROUNDTYPES_' + property?.data?.ground | translate }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.barrierFree)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-barrier-free.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_barrier_free_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.barrierFree | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.availableFrom?.dateAvailable || property?.data?.availableFrom?.stringAvailable)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-calendar.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_availableFrom_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ ((property?.data?.availableFrom?.dateAvailable | appDateTime)
              || property?.data?.availableFrom?.stringAvailable) }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.flatType)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-appartment-type.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.type_of_accomodation_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ 'FLATTYPES_' + property?.data?.flatType | translate }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.constructionYear)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-construction-year.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_constructionYear_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.constructionYear }}
            </dd>
          </dl>
          <dl
            *ngIf="!isGarage(property) && isNotNullAndUndefined(property?.data?.basementSize)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-basement.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.basement_area_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.basementSize }} m<sup>2</sup>
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.numberOfFloors)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-etage.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_numberOfFloors_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.numberOfFloors | appFloor }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.seniors)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-seniors.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_seniors_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.seniors | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.historicBuilding)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-monument-protection.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_historic_building_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.historicBuilding | appHumanizeBoolean }}
            </dd>
          </dl>
          <ng-container *ngIf="isGarage(property)">
            <dl
              *ngIf="isNotNullAndUndefined(property?.data?.garageData?.freeUntil)"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-calendar.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'general.free_until_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                {{ property?.data?.garageData?.freeUntil | appDateTime }}
              </dd>
            </dl>
            <dl
              *ngIf="isNotNullAndUndefined(property?.data?.garageData?.availableUntil)"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-calendar.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'general.available_until_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                {{ property?.data?.garageData?.availableUntil | appDateTime }}
              </dd>
            </dl>
            <dl
              *ngIf="isNotNullAndUndefined(property?.data?.garageData?.garageType)"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-garage.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'property.garage_type_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                {{ 'GARAGETYPES_' + property?.data?.garageData?.garageType |
                translate }}
              </dd>
            </dl>
            <dl
              *ngIf="isNotNullAndUndefined(property?.data?.garageData?.width)"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-width.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'general.width_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                {{ property?.data?.garageData?.width }} m
              </dd>
            </dl>
            <dl
              *ngIf="isNotNullAndUndefined(property?.data?.garageData?.height)"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-height.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'general.height_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                {{ property?.data?.garageData?.height }} m
              </dd>
            </dl>
            <dl
              *ngIf="isNotNullAndUndefined(property?.data?.garageData?.length)"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-arrows-diagonal.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'general.length_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                {{ property?.data?.garageData?.length }} m
              </dd>
            </dl>
          </ng-container>
          <ng-container *ngIf="isCommercial(property)">
            <dl
              *ngIf="isNotNullAndUndefined(property?.data?.commercialData?.airConditioning)"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-calendar.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'general.air_conditioning_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                {{ property?.data?.commercialData?.airConditioning |
                appHumanizeBoolean }}
              </dd>
            </dl>
            <dl
              *ngIf="isNotNullAndUndefined(property?.data?.commercialData?.hasCanteen)"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-width.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'general.has_canteen_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                {{ property?.data?.commercialData?.hasCanteen |
                appHumanizeBoolean }}
              </dd>
            </dl>
            <dl
              *ngIf="isNotNullAndUndefined(property?.data?.commercialData?.highVoltage)"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-arrows-diagonal.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'general.high_voltage_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                {{ property?.data?.commercialData?.highVoltage |
                appHumanizeBoolean }}
              </dd>
            </dl>
            <dl
              *ngIf="isNotNullAndUndefined(property?.data?.commercialData?.lanCables)"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-arrows-diagonal.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'general.lan_cables_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                {{ property?.data?.commercialData?.lanCables |
                appHumanizeBoolean }}
              </dd>
            </dl>
            <dl
              *ngIf="isNotNullAndUndefined(property?.data?.commercialData?.distanceToAirport)"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-calendar.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'general.distance_to_airport_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                {{ 'general.commercial_distance_in_minutes_l' | translate:{
                value: property?.data?.commercialData?.distanceToAirport } }}
              </dd>
            </dl>
            <dl
              *ngIf="isNotNullAndUndefined(property?.data?.commercialData?.distanceToHighway)"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-garage.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'general.distance_to_highway_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                {{ 'general.commercial_distance_in_minutes_l' | translate:{
                value: property?.data?.commercialData?.distanceToHighway } }}
              </dd>
            </dl>
            <dl
              *ngIf="isNotNullAndUndefined(property?.data?.commercialData?.distanceToPublicTransport)"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-height.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'general.distance_to_public_transport_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                {{ 'general.commercial_distance_in_minutes_l' | translate:{
                value: property?.data?.commercialData?.distanceToPublicTransport
                } }}
              </dd>
            </dl>
            <dl
              *ngIf="isNotNullAndUndefined(property?.data?.commercialData?.distanceToTrainStation)"
              class="property-expose__details-row"
            >
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-arrows-diagonal.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'general.distance_to_train_station_l' | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                {{ 'general.commercial_distance_in_minutes_l' | translate:{
                value: property?.data?.commercialData?.distanceToTrainStation }
                }}
              </dd>
            </dl>
          </ng-container>
        </div>
      </div>

      <div
        *ngIf="isFlat(property) || isCommercial(property)"
        class="card"
        [ngClass]="{'u-hide': !showInteriorDetailsList}"
      >
        <h2 class="title-m">{{ 'general.property_interior_l' | translate }}</h2>
        <div #interiorDetailsList>
          <dl
            *ngIf="property?.data?.parkingSpaces?.length"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-parking.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_parking_space_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.parkingSpaces?.length | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.bathRooms)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-bathroom.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_bathrooms_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.bathRooms }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.elevator)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-elevator.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.elevator_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.elevator | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.tvSatCable)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-tv.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_tv_sat_cable_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.tvSatCable | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.garden)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-tree.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_garden_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.garden | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.bicycleRoom)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-bikeroom.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_bicycle_room_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.bicycleRoom | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.attic)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-attic.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_attic_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.attic | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.fireplace)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-fireplace.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_fireplace_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.fireplace | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.storeRoom)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-storeroom.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_store_room_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.storeRoom | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.washDryRoom)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-laundryroom.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_wash_dry_room_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.washDryRoom | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.numberOfBedrooms)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-bed.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_bedrooms_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.numberOfBedrooms | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.numberOfBalconies)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-balcony.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_balconies_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.numberOfBalconies | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.numberOfTerraces)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-terrace.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_terraces_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.numberOfTerraces | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.numberOfLoggias)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-loggia.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.number_of_loggias_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.numberOfLoggias | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.balconyTerraceArea)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-room-size.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_balcony_terrace_area_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.balconyTerraceArea | appHumanizeBoolean }}
              <span>m<sup>2</sup></span>
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.landArea)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-room-size.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.land_area_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.landArea | appHumanizeBoolean }}
              <span>m<sup>2</sup></span>
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.guestToilette)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-toilet.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_guest_bathroom_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.guestToilette | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.kitchenette)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-kitchen.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_kitchenette_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.kitchenette | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.gardenUse)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-tree.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.apartment_garden_use_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.gardenUse | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.washingMachineConnection)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-washingmachine.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.washing_machine_connection_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.washingMachineConnection | appHumanizeBoolean
              }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.basementAvailable)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-basement.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.basement_available_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.basementAvailable | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.wheelchairAccessible)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-barrier-free.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.wheelchair_accessible_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.wheelchairAccessible | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.shutter)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-shutters.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.shutter_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.shutter | appHumanizeBoolean }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.bathroomEquipment?.bathtub)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-bathroom.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.bathroom_bathtub_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.bathroomEquipment?.bathtub | appHumanizeBoolean
              }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.bathroomEquipment?.bidet)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-bidet.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.bathroom_bidet_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.bathroomEquipment?.bidet | appHumanizeBoolean
              }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.bathroomEquipment?.shower)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-shower.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.bathroom_shower_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.bathroomEquipment?.shower | appHumanizeBoolean
              }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.bathroomEquipment?.urinal)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-urinal.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.bathroom_urinal_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.bathroomEquipment?.urinal | appHumanizeBoolean
              }}
            </dd>
          </dl>
          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.bathroomEquipment?.window)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-bathroom.window.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.bathroom_window_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ property?.data?.bathroomEquipment?.window | appHumanizeBoolean
              }}
            </dd>
          </dl>

          <dl
            *ngIf="isNotNullAndUndefined(property?.data?.intercomType)"
            class="property-expose__details-row"
          >
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              <svg-icon
                src="/assets/images/icons/icon-intercom.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color rect line polyline path"
              ></svg-icon>
              <span class="u-overflow-hidden">
                {{ 'general.intercom_l' | translate }}
              </span>
            </dt>
            <dd
              class="col-4 p0 property-expose__details-cell property-expose__details-cell--data text-capitalize"
            >
              {{ property?.data?.intercomType | titlecase | appHumanizeBoolean
              }}
            </dd>
          </dl>

          <ng-container *ngIf="property?.customerDefinedFieldValues?.length">
            <ng-container
              *ngFor="let fieldValue of property.customerDefinedFieldValues"
            >
              <dl
                *ngIf="isNotNullAndUndefined(fieldValue.value.value)"
                class="property-expose__details-row"
              >
                <dt
                  class="col-8 property-expose__details-cell property-expose__details-cell--title"
                >
                  <span class="u-overflow-hidden pl20">
                    {{ fieldValue.definition.label }}
                  </span>
                </dt>
                <dd
                  class="col-4 p0 property-expose__details-cell property-expose__details-cell--data text-capitalize"
                >
                  {{ fieldValue.value.type === 'DATE' ? (fieldValue.value.value
                  | appDateTime) : (fieldValue.value.value | appHumanizeBoolean)
                  }}
                </dd>
              </dl>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div
        class="card"
        *ngIf="(isFlat(property) || isCommercial(property)) && property?.data?.parkingSpaces?.length"
      >
        <h2 class="title-m">{{ 'general.parking_spaces_l' | translate }}</h2>
        <div>
          <dl class="property-expose__details-row">
            <dt
              class="col-8 property-expose__details-cell property-expose__details-cell--title"
            >
              {{ 'general.parking_space_type_l' | translate }}
            </dt>
            <dt
              class="col-4 property-expose__details-cell property-expose__details-cell--data"
            >
              {{ 'general.parking_space_count_l' | translate }} / {{
              'general.parking_places_cost_per_space_l' | translate }}
            </dt>
          </dl>
          <ng-container
            *ngFor="let parkingSpace of property?.data?.parkingSpaces; index as index"
          >
            <dl class="property-expose__details-row">
              <dt
                class="col-8 property-expose__details-cell property-expose__details-cell--title"
              >
                <svg-icon
                  src="/assets/images/icons/icon-parking.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
                <span class="u-overflow-hidden">
                  {{ 'PARKINGSPACETYPES_' + parkingSpace.type | translate }}
                </span>
              </dt>
              <dd
                class="col-4 property-expose__details-cell property-expose__details-cell--data"
              >
                {{ (parkingSpace.count || 0) }} / {{
                ((isSales(property?.marketingType) ? parkingSpace.purchasePrice
                : parkingSpace.price) || 0) | currency:'EUR' }}
              </dd>
            </dl>
          </ng-container>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!property?.rented">
      <div
        *ngIf="!isSales(property?.marketingType); else salesLegalTemplate"
        class="d-flex row mt30"
      >
        <div
          *ngIf="isProposal(propertyMatch) || isApplyPage"
          class="col-12 col-md-6"
        >
          <div class="property-expose__privacy-section">
            <div class="title-m mb8">
              {{ 'property.apply.privacy_header' | translate }}
            </div>
            <div
              [innerHTML]="'property.apply.privacy_message' | translate"
            ></div>
            <ng-container *ngIf="property?.dataPrivacyUrl">
              <br />
              <a
                [href]="property.dataPrivacyUrl"
                target="_blank"
                class="me-1 link blue"
                >{{'property.apply.privacy_link_l'| translate}}</a
              >
              <span>{{'property.apply.privacy_link_text' | translate}} </span>
            </ng-container>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="property-expose__privacy-section">
            <div class="title-m mb8">
              {{ 'schufa.schufa_info' | translate }}
            </div>
            <app-embedded-link
              [contentKey]="'schufa.schufa_reports_information'"
              [linkTarget]="'/static/schufa-info'"
              [linkTextKey]="'schufa.info_page_link'"
            >
            </app-embedded-link>
          </div>
        </div>
      </div>

      <div *ngIf="isApplyPage" class="property-expose__links">
        <span *ngFor="let link of links">
          <a class="me-3" href="{{link.url}}" target="_blank"
            >{{ link.name | translate }}</a
          >
        </span>
      </div>

      <ng-template #salesLegalTemplate>
        <div class="sales-legal-preview card-columns">
          <div
            class="card flex-1 sales-legal-preview__content"
            [innerHTML]="'sales_legal_text.explanation_l' | translate"
          ></div>

          <div
            *ngIf="property?.salesLegalTexts?.length"
            class="card flex-1 sales-legal-preview__content"
          >
            <app-sales-legal-checkboxes-preview
              [salesLegalTexts]="property.salesLegalTexts"
              (allCheckboxesChecked)="updateSalesLegalTextCheckboxesValid($event)"
            ></app-sales-legal-checkboxes-preview>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
  <app-application-actions
    *ngIf="showActionBar"
    [propertyMatch]="propertyMatch"
    [property]="property"
    [disabled]="isProcessing"
    [isApplyPage]="isApplyPage"
    [salesLegalTextCheckboxesValid]="!isSales(property?.marketingType) || (salesLegalTextCheckboxesValid || !hasSalesCheckboxes)"
    (landingApply)="onLandingApply()"
    (applyAnonymous)="onApplyAnonymous($event)"
    (openSalesModal)="onOpenSalesModal()"
  ></app-application-actions>
</div>

<ng-template #loadingTemplate>
  <div class="property-expose__body modal-body modal__body--loading">
    <div class="loading-area">
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>
</ng-template>
