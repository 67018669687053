import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  RentDepositPaymentOption,
  RentDepositInfo,
  RentDepositModel,
  RuvApplianceStatus
} from '@ui/shared/models';

@Component({
  selector: 'app-rent-deposit-status-modal',
  templateUrl: './rent-deposit-status-modal.component.html',
  styleUrls: ['./rent-deposit-status-modal.component.scss']
})
export class RentDepositStatusModalComponent {
  public rentDepositInfo: RentDepositInfo;
  public rentDepositModel: RentDepositModel;

  public get inRevision() {
    return (
      this.rentDepositInfo?.ruvData?.applianceStatus ===
        RuvApplianceStatus.UNDER_REVISION ||
      this.rentDepositInfo?.ruvData?.applianceStatus ===
        RuvApplianceStatus.TECHNICAL_PROBLEM
    );
  }

  public get isApproved() {
    return (
      this.rentDepositInfo?.ruvData?.applianceStatus ===
      RuvApplianceStatus.APPROVED
    );
  }

  public get isDeclined() {
    return (
      this.rentDepositInfo?.ruvData?.applianceStatus ===
      RuvApplianceStatus.DECLINED
    );
  }

  public get failed() {
    return (
      this.rentDepositInfo?.ruvData?.applianceStatus ===
      RuvApplianceStatus.REQUEST_FAILED
    );
  }

  public get isYearly() {
    return (
      this.rentDepositModel?.contractData?.paymentType ===
      RentDepositPaymentOption.YEARLY
    );
  }

  public get isMonthly() {
    return (
      this.rentDepositModel?.contractData?.paymentType ===
      RentDepositPaymentOption.MONTHLY
    );
  }

  constructor(private ngbActiveModal: NgbActiveModal) {}

  public close() {
    this.ngbActiveModal.dismiss();
  }
}
