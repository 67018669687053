import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonTypeEnum } from 'libs/components/atoms/button/button.enum';
import { externalDataPrivacyLink } from 'tenant-pool/config';

@Component({
  selector: 'app-social-login-confirmation-modal',
  templateUrl: './social-login-confirmation-modal.component.html',
  styleUrls: ['./social-login-confirmation-modal.component.scss']
})
export class SocialLoginConfirmationModalComponent {
  public type: ButtonTypeEnum = ButtonTypeEnum.LIGHT_BORDERED;
  public dataProtectionLink = externalDataPrivacyLink;

  constructor(private ngbActiveModal: NgbActiveModal) {}

  public continue() {
    this.ngbActiveModal.close();
  }

  public close() {
    this.ngbActiveModal.dismiss();
  }
}
