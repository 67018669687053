import moment from 'moment';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';

import {
  ApplicantStatus,
  AppointmentInvitationState,
  AppointmentsBundles,
  filteredOutFromList,
  Profile,
  PropertyApplicationStatus,
  PropertyMatchBean,
  PropertySearcherType
} from '@ui/shared/models';

import * as fromBaseState from 'libs/infrastructure/base-state';
import { CheckforConversationInterface } from 'libs/components/legacy/messenger/model/interface';

import * as fromAppState from 'tenant-pool/+state';
import { isPropertyTypeFlat } from 'libs/utils';

@Component({
  selector: 'app-property-info',
  templateUrl: './property-info.component.html',
  styleUrls: ['./property-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyInfoComponent implements OnInit {
  public applicantStatus = PropertyApplicationStatus;

  public userId: string;
  public appointmentSlotsAvailable: boolean;

  @Input() item: PropertyMatchBean;
  @Input() index: number;
  @Input() isProcessing: boolean;
  @Input() profileComplete: boolean;
  @Input() appointments: AppointmentsBundles[];
  @Input() blockedChat: boolean;
  @Input() participatedAgents: Profile[];

  @Output() showDetails = new EventEmitter<PropertyMatchBean>();
  @Output() apply = new EventEmitter<PropertyMatchBean>();
  @Output() removeProperty = new EventEmitter<PropertyMatchBean>();
  @Output() editProfile = new EventEmitter<void>();
  @Output() viewInvitation = new EventEmitter<PropertyMatchBean>();
  @Output() viewAppointment = new EventEmitter<PropertyMatchBean>();
  @Output() noFittingAppointment = new EventEmitter<void>();
  @Output() answerCustomQuestion = new EventEmitter<PropertyMatchBean>();
  @Output() setIntent = new EventEmitter<{
    intent: string;
    application: PropertyMatchBean;
  }>();
  @Output() shareProperty = new EventEmitter<string>();
  @Output() exportToCalendar = new EventEmitter<PropertyMatchBean>();
  @Output() exportPDF = new EventEmitter<string>();
  @Output() cancelAppointment = new EventEmitter<string>();
  @Output() openChat = new EventEmitter<CheckforConversationInterface>();

  public get isFlat() {
    return isPropertyTypeFlat(this.item.property?.type);
  }

  public get status() {
    return this.item?.status;
  }

  public get isDeleted() {
    return filteredOutFromList.includes(this.status);
  }

  public get showAddress() {
    return (
      this.item.property.data?.showAddress ||
      this.status === PropertyApplicationStatus.ACCEPTED
    );
  }

  public get isProposal() {
    return this.item?.type === PropertySearcherType.PROPOSAL;
  }

  public get isNotBlocked() {
    return !this.item?.applicationBlocked;
  }

  public get isIntent() {
    return this.status === PropertyApplicationStatus.INTENT;
  }

  public get isNoIntent() {
    return this.status === PropertyApplicationStatus.NO_INTENT;
  }

  public get hasCustomQuestion() {
    return this.item.hasQuestions;
  }

  public get picture() {
    return this.item?.property?.data?.attachments?.[0];
  }

  public get propertyId() {
    return this.item?.property?.id;
  }

  public get canChooseAnotherAppointment() {
    return (
      this.item?.status === ApplicantStatus.DECLARE_INTENT &&
      this.appointmentSlotsAvailable
    );
  }

  public get showExposeDownload() {
    return (
      this.item.property.downloadExposeAllowed &&
      (this.item.status === ApplicantStatus.DECLARE_INTENT ||
        this.item.status === ApplicantStatus.INTENT ||
        this.item.status === ApplicantStatus.INVITED_TO_VIEWING ||
        this.item.status === ApplicantStatus.ATTENDING_TO_VIEWING ||
        this.item.status === ApplicantStatus.TENANT)
    );
  }

  constructor(private store: Store<fromBaseState.BaseState>) {}

  ngOnInit() {
    this.appointmentSlotsAvailable =
      this.item.appointmentSlotsAvailable ||
      this.hasExclusiveAppointments(this.item, this.appointments);
  }

  public onApply() {
    this.apply.emit(this.item);
  }

  public onRemoveProperty() {
    this.removeProperty.emit({
      ...this.item,
      isProposal: this.isProposal
    });
  }

  public openSelfDisclosureModal() {
    this.store.dispatch(new fromAppState.LoadSelfDisclosureData(this.item));
    this.store.dispatch(new fromAppState.OpenSelfDisclosuresModal());
  }

  public onEditProfile() {
    this.editProfile.emit();
  }

  public onViewInvitation() {
    this.viewInvitation.emit(this.item);
  }

  public onViewAppointment() {
    this.viewAppointment.emit(this.item);
  }

  public onNoFittingAppointment() {
    this.noFittingAppointment.emit();
  }

  public onSetIntent(intent?: PropertyApplicationStatus) {
    this.setIntent.emit({
      intent,
      application: this.item
    });
  }

  public onAnswerCustomQuestion() {
    this.answerCustomQuestion.emit(this.item);
  }

  public onShareProperty() {
    this.shareProperty.emit(this.item.property.applyLink);
  }

  public onExportToCalendar() {
    if (!this.item.upcomingAppointmentId) return;
    this.exportToCalendar.emit(this.item);
  }

  public onExportPDF() {
    this.exportPDF.emit(this.item.id);
  }

  public onShowDetails() {
    if (!this.isFlat) return;
    this.showDetails.emit(this.item);
  }

  public get isShowSelfDisclosure() {
    return !!this.item?.property?.selfDisclosureId;
  }

  public onCancelViewing() {
    const appointmentId = this.appointments
      .filter(
        appointmentsBundles =>
          appointmentsBundles.applicationId === this.item.id
      )
      .map(appointment =>
        appointment.appointmentAcceptances.find(
          acceptedAppointment =>
            acceptedAppointment.state === AppointmentInvitationState.ACTIVE
        )
      )
      .map(appointment => appointment.id);

    this.cancelAppointment.emit(appointmentId.toString());
  }

  public hasAppointmentInTheFuture(upcomingAppointmentDate: Date) {
    return moment(upcomingAppointmentDate).isAfter(moment.now());
  }

  private hasExclusiveAppointments(
    application: PropertyMatchBean,
    appointmentBundles: AppointmentsBundles[] = []
  ) {
    return appointmentBundles
      .filter(
        appointmentsBundle =>
          appointmentsBundle.applicationId === application.id
      )
      .map(appointmentsBundle => {
        const invitedAppointmentIds =
          appointmentsBundle.appointmentInvitations.map(
            invites => invites.appointmentId
          );
        const invitedAppointmentList = appointmentsBundle.appointments.filter(
          appointment => invitedAppointmentIds?.includes(appointment.id)
        );
        return (
          invitedAppointmentList.filter(
            appointment => appointment.exclusiveAttendees.length > 0
          )?.length > 0
        );
      })
      .reduce((a: boolean, b: boolean) => a || b, false);
  }
}
