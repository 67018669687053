<div class="profile__content">
  <form [formGroup]="form" class="profile__subform">
    <div class="mb20">
      <app-form-field>
        <app-form-field-label
          >{{ 'general.proof_of_income_l' | translate }}</app-form-field-label
        >
        <app-attachments
          [showDownload]="false"
          [showRemove]="true"
          [isDocument]="true"
          [hideFileUpload]="false"
          [accept]="acceptedTypes"
          [size]="acceptedSize"
          [multiple]="true"
          [subInformation]="'PDF / JPG / PNG ' + ('general.file_size_up_to_l' | translate) + ' 20 MB'"
          formControlName="incomeProof"
        ></app-attachments>
      </app-form-field>
    </div>

    <div class="mb20" *ngIf="showCreditScreeningDocumentUploadArea">
      <app-form-field>
        <app-form-field-label
          >{{ 'general.credit_score_l' | translate }}</app-form-field-label
        >
        <app-attachments
          [showDownload]="false"
          [showRemove]="true"
          [isDocument]="true"
          [hideFileUpload]="false"
          [accept]="acceptedTypes"
          [size]="acceptedSize"
          [multiple]="true"
          [subInformation]="'PDF / JPG / PNG ' + ('general.file_size_up_to_l' | translate) + ' 20 MB'"
          formControlName="creditScore"
        ></app-attachments>
      </app-form-field>
    </div>

    <div class="credit_sore" *ngIf="schufaCard && !creditScoreValue">
      <div
        *ngIf="showCreditScreeningDocumentUploadArea"
        class="credit_sore__or"
      >
        <span>{{'credit_score.or_l' | translate}}</span>
      </div>

      <div class="mb20 schufa-container card">
        <div class="card-header d-flex">
          <app-badge [color]="'state-500'"
            >{{'schufa.get_credit_report_title_l' | translate}}</app-badge
          >
          <app-badge class="ml12" [color]="'neutral-light-dark'">
            {{'schufa.get_credit_report_optional_l' | translate}}</app-badge
          >
        </div>
        <div class="card-body d-flex row m0">
          <div class="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-2 p0">
            <img src="/assets/images/icons/icon-schufa.svg" alt="schufa" />
          </div>
          <div class="col pr0">
            <p>{{'profile.schufa_banner.header_l' | translate}}</p>
            <span> {{'profile.schufa_banner.message_l' | translate}} </span>
            <span
              class="schufa-container__more-info"
              (click)="schufaMoreInfo()"
            >
              {{'profile.schufa_banner.more_info_l' | translate}}
            </span>
          </div>
          <div class="col-12 p0 mt10">
            <app-button
              (clickEvent)="onSchufaRegister()"
              [type]="'light-bordered'"
            >
              {{'profile.schufa_banner.get_score_a' | translate}}
            </app-button>
          </div>
        </div>
      </div>
    </div>

    <div class="mb20">
      <app-form-field>
        <app-form-field-label
          >{{ 'general.other_documents_l' | translate }}</app-form-field-label
        >
        <app-attachments
          [showDownload]="false"
          [showRemove]="true"
          [isDocument]="true"
          [hideFileUpload]="false"
          [accept]="acceptedTypes"
          [size]="acceptedSize"
          [multiple]="true"
          [subInformation]="'PDF / JPG / PNG ' + ('general.file_size_up_to_l' | translate) + ' 20 MB'"
          formControlName="otherDocuments"
        ></app-attachments>
      </app-form-field>
    </div>
  </form>
</div>
