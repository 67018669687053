import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  MovingAuctionResponse,
  MovingAuctionResponseType
} from '@ui/shared/models';

@Component({
  selector: 'app-moving-auction-modal',
  templateUrl: './moving-auction-modal.component.html',
  styleUrls: ['./moving-auction-modal.component.scss']
})
export class MovingAuctionModalComponent {
  public movingAuctionResponse: MovingAuctionResponse;

  public get isCreated() {
    return (
      this.movingAuctionResponse.responseType ===
      MovingAuctionResponseType.CREATED
    );
  }

  public get isAccepted() {
    return (
      this.movingAuctionResponse.responseType ===
      MovingAuctionResponseType.ACCEPTED
    );
  }

  public get hasError() {
    return (
      this.movingAuctionResponse.responseType ===
      MovingAuctionResponseType.ERROR
    );
  }

  constructor(private ngbActiveModal: NgbActiveModal) {}

  public close() {
    this.ngbActiveModal.dismiss();
  }
}
