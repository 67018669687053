import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseStep } from 'libs/components/legacy/wizard/wizard-step/base-step';
import { defaultDocumentsConfig } from 'libs/config';
import {
  SearchProfile,
  NameValue,
  DropdownStepperParent,
  HousingPermissionType,
  getWbsOptions,
  StateOfGermany
} from '@ui/shared/models';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-profile-dk-zero',
  templateUrl: './profile-dk-zero.component.html',
  styleUrls: ['./profile-dk-zero.component.scss']
})
export class ProfileDkZeroComponent extends BaseStep implements OnInit {
  private _housingPermissionTypes: NameValue<string>[];
  @Input() searchProfiles: SearchProfile[];
  @Input() guestMode = false;
  @Input()
  get housingPermissionTypes() {
    return this._housingPermissionTypes;
  }
  set housingPermissionTypes(value) {
    this._housingPermissionTypes = [
      this.housingPermissionDefaultType,
      ...(value || [])
    ];
  }

  @Output() editSearchProfile = new EventEmitter<SearchProfile>();
  @Output() deleteSearchProfile = new EventEmitter<string>();
  @Output() toggleSearchProfile = new EventEmitter<string>();
  @Output() addSearchProfile = new EventEmitter();

  public WBS_STEPPER_OPTIONS: DropdownStepperParent<HousingPermissionType>[] =
    [];

  constructor(private store: Store) {
    super();
  }

  private housingPermissionDefaultType: NameValue<string> = {
    value: null,
    name: 'general.housing_permission_not_available_l'
  };

  public housingPermissionDocumentAcceptance = {
    types: defaultDocumentsConfig.allAcceptedTypes,
    size: defaultDocumentsConfig.acceptedDocumentSize
  };

  public get housingPermissionTypeValue() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.form.get('housingPermission.type').value;
  }

  public onEditSearchProfile(searchProfile: SearchProfile) {
    this.editSearchProfile.emit(searchProfile);
  }

  public onDeleteSearchProfile(id: string) {
    this.deleteSearchProfile.emit(id);
  }

  public onToggleSearchProfile(id: string) {
    this.toggleSearchProfile.emit(id);
  }

  public onAddSearchProfile() {
    this.addSearchProfile.emit();
  }

  public nextStep() {
    if (!this.housingPermissionTypeValue) {
      this.form.get('housingPermission.amountPeople').patchValue(null);
      this.form.get('wbsDocument').patchValue(null);
    }
    super.nextStep();
  }

  ngOnInit() {
    super.ngOnInit();

    this.store
      .select(fromBaseState.getConstants)
      .pipe(take(1))
      .subscribe(constants => {
        this.WBS_STEPPER_OPTIONS = getWbsOptions(
          constants.germanyStates as NameValue<StateOfGermany>[]
        );
      });
  }
}
