<div class="action-button__content">
  <app-button
    [disabled]="disabled"
    (clickEvent)="onApply()"
    *ngIf="buttonType === 'NEW_MATCH'"
    [type]="'primary'"
    >{{ 'property.apply_now_a' | translate }}</app-button
  >

  <div
    *ngIf="buttonType === 'WAITING_FOR_LANDLORD' || buttonType === 'NO_OPEN_SLOTS' || buttonType === 'INTENT'"
  >
    <app-button
      *ngIf="!profileComplete"
      (clickEvent)="onEditProfile()"
      [type]="'light-bordered'"
      [iconLeft]="'edit'"
    >
      {{ 'property.complete_profile_a' | translate }}
    </app-button>
    <app-button
      [disabled]="disabled"
      (clickEvent)="onEditProfile()"
      *ngIf="profileComplete"
      [type]="'primary'"
      >{{ 'profile.edit_profile_a' | translate }}</app-button
    >
  </div>
  <app-button
    [disabled]="disabled"
    (clickEvent)="onNoFittingAppointment()"
    *ngIf="buttonType === 'ALL_APPOINTMENTS_DECLINED'"
    [type]="'primary'"
  >
    {{ 'property.no_fitting_appointment_a' | translate }}
  </app-button>
  <app-button
    [disabled]="disabled"
    (clickEvent)="onViewInvitation()"
    *ngIf="buttonType === 'INVITED_TO_VIEWING'"
    [type]="'primary'"
  >
    {{ 'property.view_invitation_a' | translate }}
  </app-button>
  <app-button
    [disabled]="disabled"
    (clickEvent)="onViewAppointment()"
    *ngIf="buttonType === 'ATTENDING_TO_VIEWING'"
    [type]="'primary-inverse'"
    [elevation]="1"
  >
    <div class="action-button__calendar">
      <div class="calendar__time">
        <svg-icon
          src="/assets/images/icons/calendar.svg"
          [applyClass]="true"
          [svgStyle]="{'width.px': 15}"
          class="secondary-svg-color path rect"
        ></svg-icon>
        <span class="pl5 pr5"
          >{{ item?.upcomingAppointmentDate | appDateTime }}</span
        >
        <svg-icon
          src="/assets/images/icons/icon-clock.svg"
          [applyClass]="true"
          [svgStyle]="{'width.px': 15}"
          class="secondary-svg-color path circle polyline"
        ></svg-icon>
        <span class="pl10"
          >{{ item?.upcomingAppointmentDate | appDateTime: { withDate: false,
          withTime: true } }}</span
        >
      </div>
    </div>
  </app-button>
  <div
    class="row m0 intent-buttons__container"
    *ngIf="buttonType === 'DECLARE_INTENT'"
  >
    <app-intent-buttons [disabled]="disabled" (setIntent)="onSetIntent($event)">
    </app-intent-buttons>
  </div>
</div>
