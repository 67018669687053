import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StepTenant } from 'tenant-pool/models/step.tenant';

@Component({
  selector: 'app-profile-dk-explanation',
  templateUrl: './profile-dk-explanation.component.html',
  styleUrls: ['./profile-dk-explanation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileDkExplanationComponent {
  @Input() currentStep: StepTenant;
  @Input() stepIndex: number;

  public get label() {
    return `general.dk_phase_${this.currentStep.name}_l`;
  }

  public get description() {
    return `general.dk_phase_${this.currentStep.name}_description_l`;
  }
}
