<dl class="cost-details__row">
  <dt class="col-8 cost-details__cell cost-details__cell--title">
    <ng-content select="svg-icon"></ng-content>
    <span class="u-overflow-hidden"> {{ translationKey | translate }} </span>
  </dt>
  <dd class="col-4 cost-details__cell cost-details__cell--data">
    {{ textInsteadOfProperty ? (textInsteadOfProperty | translate) : (property
    || 0 | currency:'EUR') }}
    <span *ngIf="showPlusVat" class="ps-1">
      <small>{{ 'general.plus_vat_l' | translate }}</small>
    </span>
    <span *ngIf="additionalText" class="ps-1">
      <small>{{ additionalText | translate }}</small>
    </span>
  </dd>
</dl>
