import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Route } from '@angular/router';
import { Store } from '@ngrx/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Observable } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { ModalService } from 'libs/components/legacy/modal';
import {
  Attachment,
  GenericFormGroup,
  PropertySearcherUser
} from '@ui/shared/models';
import { customEmailValidator } from 'libs/components/legacy/form/controls/validation';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { ActionState } from 'libs/state-utils';
import { WINDOW_REF } from 'libs/infrastructure';

import {
  dialogConfig,
  MainPageNavigation,
  notificationConfig
} from 'tenant-pool/config';
import * as fromAppState from 'tenant-pool/+state';
import * as fromActions from 'tenant-pool/+state/schufa/schufa.actions';
import * as fromSelectors from 'tenant-pool/+state/schufa/schufa.selectors';
import {
  SchufaDeliveryResponseData,
  SchufaError,
  SchufaOrderResponseData,
  SchufaStatus
} from 'tenant-pool/core/queries';

import {
  SendSchufaDelivery,
  SchufaFinish,
  SchufaFormValues,
  SendSchufaPayment,
  SchufaPersonalInformation
} from 'tenant-pool/models';

import { SchufaStepsRoutes } from '../schufa-step.routes';
import * as fromDialogConfig from '../schufa-dialog.config';

const GENDERTYPES = [
  { label: 'GENDERTYPES_MALE', value: 'Herr' },
  { label: 'GENDERTYPES_FEMALE', value: 'Frau' }
];

@UntilDestroy()
@Component({
  selector: 'app-schufa-wizard',
  templateUrl: './schufa-wizard.component.html',
  styleUrls: ['./schufa-wizard.component.scss']
})
export class SchufaWizardComponent implements OnInit, OnDestroy {
  @Input() userData: PropertySearcherUser;
  @Output() uploadCreditReport = new EventEmitter<Attachment>();
  @Output() finishSchufa = new EventEmitter<Attachment>();

  public currentStep$: Observable<number>;
  public currentForm$: Observable<FormGroup>;
  public schufaForm: GenericFormGroup<SchufaFormValues>;

  public personalDataActionState$: Observable<ActionState>;
  public paymentActionState$: Observable<ActionState>;
  public orderActionState$: Observable<ActionState>;
  public deliveryActionState$: Observable<ActionState>;

  public personalDataResonse$: Observable<SchufaOrderResponseData>;
  public paymentResponse$: Observable<SchufaOrderResponseData>;
  public paymentErrorCount$: Observable<number>;
  public orderResponse$: Observable<SchufaDeliveryResponseData>;
  public deliveryResponse$: Observable<SchufaDeliveryResponseData>;
  public deliveryResponseError: SchufaError[];

  public processing$: Observable<boolean>;
  public genderTypes = GENDERTYPES;

  public allSteps: { name: string; nameNice: string }[];
  private maxIbanAttemptCount = 3;

  get personalInformation() {
    return this.schufaForm.get('personalInformation') as FormGroup;
  }

  get personalData() {
    return this.personalInformation.get('personalData') as FormGroup;
  }

  get address() {
    return this.personalData.get('address') as FormGroup;
  }

  get preAddress() {
    return this.personalData.get('preAddress') as FormGroup;
  }

  get payment() {
    return this.schufaForm.get('payment') as FormGroup;
  }

  get delivery() {
    return this.schufaForm.get('delivery') as FormGroup;
  }

  get identityData() {
    return this.delivery.get('identityData') as FormGroup;
  }

  get finish() {
    return this.schufaForm.get('finish') as FormGroup;
  }

  get iban() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.payment.get('sepaLev').get('iban').value;
  }

  constructor(
    private fb: FormBuilder,
    private store: Store<fromAppState.AppState>,
    private modalService: ModalService,
    @Inject(WINDOW_REF) private windowRef: Window
  ) {}

  public ngOnInit() {
    this.personalDataActionState$ = this.store.select(
      fromSelectors.getSchufaPersonalDataActionState
    );
    this.personalDataResonse$ = this.store.select(
      fromSelectors.getSchufaPersonalDataResponseData
    );

    this.paymentActionState$ = this.store.select(
      fromSelectors.getSchufaPaymentActionState
    );
    this.paymentResponse$ = this.store.select(
      fromSelectors.getSchufaPaymentResponseData
    );
    this.paymentErrorCount$ = this.store.select(
      fromSelectors.getSchufaPaymentErrorCounter
    );

    this.deliveryActionState$ = this.store.select(
      fromSelectors.getSchufaDeliveryActionState
    );
    this.deliveryResponse$ = this.store.select(
      fromSelectors.getSchufaDeliveryResponseData
    );

    this.orderActionState$ = this.store.select(
      fromSelectors.getSchufaOrderActionState
    );
    this.orderResponse$ = this.store.select(
      fromSelectors.getSchufaOrderResponseData
    );

    this.allSteps = this.getAllSteps();
    this.currentStep$ = this.store.select(fromSelectors.getCurrentStepNumber);
    this.schufaForm = this.getSchufaForm();
    this.preFillForm();
    this.currentForm$ = this.currentStep$.pipe(
      tap((currentStep: number) => {
        if (currentStep === 3) {
          this.delivery.patchValue({
            identityData: {
              iBAN: this.payment.get('sepaLev').get('iban').value
            }
          });
        }
      }),
      map((currentStep: number) => {
        return this.schufaForm.get(
          this.allSteps[currentStep - 1].name
        ) as FormGroup;
      })
    );

    this.personalData.valueChanges.pipe(untilDestroyed(this)).subscribe(val => {
      this.payment.patchValue({
        sepaLev: {
          bankAccountOwner: `${String(val.firstName)} ${String(val.lastName)}`
        }
      });
    });

    this.store
      .select(fromSelectors.getSchufaPaymentErrorCounter)
      .pipe(
        filter(counter => counter === this.maxIbanAttemptCount),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.onRetry();
        this.store.dispatch(
          new fromBaseState.ShowError(
            notificationConfig.schufa.payment.errorThreeTimes
          )
        );
      });
  }

  public ngOnDestroy(): void {
    this.store.dispatch(new fromActions.InitialState());
  }

  previousStep() {
    this.store.dispatch(new fromActions.PreviousStep());
  }

  public cancel() {
    this.modalService
      .openConfirmation({
        data: dialogConfig.schufa.cancel,
        backdrop: 'static',
        keyboard: false
      })
      .onClose()
      .subscribe(() => this.finishSchufa.emit());
  }

  public onSchufaPersonalData(stepName: string) {
    const { lastYearMoved, ...personalInformation } =
      this.personalInformation.value;
    const address = this.address.value;
    const preAddress = this.preAddress.value;
    const data = {
      ...personalInformation
    };

    if (
      address.houseNumber?.length === 0 ||
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      preAddress.find(item => item.houseNumber?.length === 0)
    ) {
      return this.modalService
        .openConfirmation({
          data: {
            ...fromDialogConfig.schufaAddressNoHouseNumber
          }
        })
        .onClose()
        .subscribe(() => {
          return this.schufaPersonalDataAction(stepName, data);
        });
    } else {
      return this.schufaPersonalDataAction(stepName, data);
    }
  }

  public onSchufaPayment() {
    this.store.dispatch(new fromActions.SendSchufaPayment(this.payment.value));
    this.store
      .select(fromSelectors.getSchufaPaymentActionState)
      .pipe(
        filter(state => !state.pending),
        switchMap(() =>
          this.store.select(fromSelectors.getSchufaPaymentResponseData)
        ),
        withLatestFrom(
          this.store.select(fromSelectors.getSchufaPaymentErrorCounter),
          this.store.select(fromSelectors.getSchufaPaymentRetryCounter)
        ),
        take(1)
      )
      .subscribe(([paymentResponseData, errorCount, retryCounter]) => {
        if (
          retryCounter > 1 ||
          (paymentResponseData.error &&
            paymentResponseData.error.length === 0 &&
            errorCount < this.maxIbanAttemptCount)
        ) {
          // continue with order step
          this.onSchufaOrder('payment');
        }
      });
  }

  public onSchufaOrder(completedStepName: string) {
    this.store.dispatch(new fromActions.SendSchufaOrder());
    this.store
      .select(fromSelectors.getSchufaOrderActionState)
      .pipe(
        filter(state => !state.pending && (state.done || !!state.error)),
        switchMap(() =>
          this.store.select(fromSelectors.getSchufaOrderResponseData)
        ),
        take(1)
      )
      .subscribe(orderResponseData => {
        if (
          orderResponseData?.error &&
          orderResponseData?.error?.length === 0
        ) {
          this.store.dispatch(
            new fromActions.NextStep(completedStepName, this.userData)
          );
        }
      });
  }

  public onSchufaDelivery(
    stepName: string,
    deliveryValues: SendSchufaDelivery
  ) {
    this.store.dispatch(new fromActions.SendSchufaDelivery(deliveryValues));
    this.store
      .select(fromSelectors.getSchufaDeliveryActionState)
      .pipe(
        filter(state => !state.pending),
        switchMap(() =>
          this.store.select(fromSelectors.getSchufaDeliveryResponseData)
        ),
        take(1)
      )
      .subscribe(deliveryResponseData => {
        if (
          deliveryResponseData?.error &&
          deliveryResponseData?.error.length === 0
        ) {
          this.store.dispatch(
            new fromActions.NextStep(stepName, this.userData)
          );
        } else {
          this.deliveryResponseError = deliveryResponseData?.error;
        }
      });
  }

  public onOpenSchufaPaymentMoreInformation() {
    this.modalService.openConfirmation({
      data: {
        ...fromDialogConfig.schufaPaymentMoreInformation,
        acknowledge: true,
        innerHTML: true
      }
    });
  }

  public onSchufaIdentify(value: SendSchufaDelivery) {
    this.onSchufaDelivery('delivery', value);
  }

  public onDownloadCreditReportPDF(url: string) {
    if (!url) return;
    this.windowRef.open(url, '_blank');
  }

  public onLogin() {
    this.store.dispatch(
      new fromBaseState.Go({ path: [MainPageNavigation.LOGIN] })
    );
  }

  public onUploadCreditReport(creditReport: Attachment) {
    this.uploadCreditReport.emit(creditReport);
  }

  public onRetry() {
    this.store.dispatch(new fromActions.InitialState());
    this.schufaForm.reset();
    this.preFillForm();
    this.store.dispatch(
      new fromBaseState.Go({
        path: [
          this.userData
            ? `${MainPageNavigation.SERVICE_CENTER}/${MainPageNavigation.SCHUFA}`
            : MainPageNavigation.SCHUFA,
          'personalInformation'
        ]
      })
    );
  }

  public onFinish() {
    this.finishSchufa.emit();
  }

  private getSchufaForm() {
    const personalInformation: GenericFormGroup<SchufaPersonalInformation> =
      this.fb.group({
        personalData: this.fb.group({
          salutation: [null, Validators.required],
          lastName: ['', Validators.required],
          firstName: ['', Validators.required],
          dateOfBirth: [null, Validators.required],
          address: this.fb.group({
            country: ['DEU', Validators.required],
            zipCode: ['', Validators.required],
            city: ['', Validators.required],
            street: ['', Validators.required],
            houseNumber: ['']
          }),
          preAddress: this.fb.array([]),
          email: [
            '',
            Validators.compose([Validators.required, customEmailValidator])
          ]
        }),
        approvalTermsOfCondition: [false, Validators.requiredTrue],
        lastYearMoved: [false]
      }) as GenericFormGroup<SchufaPersonalInformation>;

    const payment: GenericFormGroup<SendSchufaPayment> = this.fb.group({
      sepaLev: this.fb.group({
        bankAccountOwner: ['', Validators.required],
        iban: [
          '',
          Validators.compose([
            Validators.required,
            Validators.maxLength(22),
            Validators.pattern('^DE+[ \\d]*')
          ])
        ],
        approvalSEPA: [false, Validators.requiredTrue]
      })
    });

    const delivery: GenericFormGroup<SendSchufaDelivery> = this.fb.group({
      identityData: [],
      deliverySelection: [null],
      postDelivery: false
    });

    delivery.setErrors({ incorrect: true });

    const finish: GenericFormGroup<SchufaFinish> = this.fb.group({
      creditReport: [null, Validators.required]
    });

    return this.fb.group({
      personalInformation,
      payment,
      delivery,
      finish
    }) as GenericFormGroup<SchufaFormValues>;
  }

  private preFillForm() {
    if (
      !this.userData ||
      (Object.keys(this.userData) && Object.keys(this.userData).length === 0)
    ) {
      this.personalData.patchValue({ address: { country: 'DEU' } });
      return;
    }
    this.personalInformation.patchValue({
      lastYearMoved: false
    });

    this.personalData.patchValue({
      lastName:
        this.userData && this.userData.profile && this.userData.profile.name,
      firstName:
        this.userData &&
        this.userData.profile &&
        this.userData.profile.firstname,
      dateOfBirth:
        this.userData &&
        this.userData.profile &&
        this.userData.profile.dateOfBirth,
      address: {
        country: 'DEU',
        zipCode:
          this.userData &&
          this.userData.address &&
          this.userData.address.zipCode,
        city:
          this.userData && this.userData.address && this.userData.address.city,
        street:
          this.userData &&
          this.userData.address &&
          this.userData.address.street,
        houseNumber:
          this.userData &&
          this.userData.address &&
          this.userData.address.houseNumber
      },
      email: this.userData && this.userData.email
    });

    this.payment.patchValue({
      sepaLev: {
        bankAccountOwner: `${this.userData.profile.firstname} ${this.userData.profile.name}`
      }
    });
  }

  private getAllSteps() {
    return SchufaStepsRoutes.map((routeObject: Route) => ({
      name: routeObject.path,
      nameNice: routeObject.data.title
    }));
  }

  private schufaPersonalDataAction(
    stepName: string,
    data: SchufaPersonalInformation
  ) {
    this.store.dispatch(new fromActions.SendSchufaPersonalData(data));
    this.store
      .select(fromSelectors.getSchufaPersonalDataActionState)
      .pipe(
        filter(state => !state.pending),
        switchMap(() =>
          this.store.select(fromSelectors.getSchufaPersonalDataResponseData)
        ),
        take(1)
      )
      .subscribe(personalDataResponseData => {
        if (
          !this.shouldCancel(personalDataResponseData.status) &&
          personalDataResponseData.error &&
          personalDataResponseData.error.length === 0
        ) {
          this.store.dispatch(
            new fromActions.NextStep(stepName, this.userData)
          );
        }
      });
  }

  private shouldCancel(status: SchufaStatus) {
    return status && status.next && status.next.split(' ')[0] === 'delete';
  }
}
