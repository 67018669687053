import { Component, Inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { NotificationService, WINDOW_REF } from 'libs/infrastructure';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { BaseStep } from 'libs/components/legacy/wizard/wizard-step/base-step';

import { defaultDocumentsConfig, MainPageNavigation } from 'tenant-pool/config';
import { ENVIRONMENT_CONFIG, EnvironmentConfig } from 'tenant-pool/core';

@Component({
  selector: 'app-profile-dk-three',
  templateUrl: './profile-dk-three.component.html',
  styleUrls: ['./profile-dk-three.component.scss']
})
export class ProfileDkThreeComponent extends BaseStep {
  @Input() schufaCard: boolean;
  @Input() showCreditScreeningDocumentUploadArea: boolean;
  @Input() isRegister: boolean;

  public acceptedTypes = defaultDocumentsConfig.allAcceptedTypes;
  public acceptedSize = defaultDocumentsConfig.acceptedDocumentSize;

  public get creditScoreValue() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return this.form.get('creditScore').value?.length !== 0;
  }

  constructor(
    private store: Store<fromBaseState.BaseState>,
    private notificationService: NotificationService,
    @Inject(ENVIRONMENT_CONFIG) private environment: EnvironmentConfig,
    @Inject(WINDOW_REF) private windowRef: Window
  ) {
    super();
  }

  public schufaMoreInfo(): void {
    this.notificationService.showInfoModal(
      'schufa.more_info_title_l',
      'schufa.more_info_msg_l',
      'general.ok',
      true
    );
  }

  public onSchufaRegister(): void {
    if (this.isRegister) {
      // during registration, we don't want the user to loose already entered data,
      // so we open SCHUFA in new tab.
      const url = `${this.environment.property_searcher_base_url}/de/schufa`;
      this.windowRef.open(url, '_blank');
    } else {
      this.store.dispatch(
        new fromBaseState.Go({
          path: [
            MainPageNavigation.SERVICE_CENTER,
            MainPageNavigation.SCHUFA,
            'personalInformation'
          ]
        })
      );
    }
  }
}
