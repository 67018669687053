import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GalleryModule as ImportedGalleryModule } from 'ng-gallery';
import 'hammerjs';
import { LightboxModule } from 'ng-gallery/lightbox';
// ngx-gallery requires hammerjs for swipe functionalities

import { GalleryComponent } from './components/gallery.component';

const exportComponents = [GalleryComponent];

const declarations = [...exportComponents];

@NgModule({
  imports: [ImportedGalleryModule, LightboxModule, CommonModule],
  declarations,
  exports: exportComponents
})
export class GalleryModule {}
