import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Person } from '@ui/shared/models';

@Component({
  selector: 'app-contract-help-modal',
  templateUrl: './contract-help-modal.component.html',
  styleUrls: ['./contract-help-modal.component.scss']
})
export class ContractHelpModalComponent {
  public contact: Person;
  public isStepCode: boolean;
  public customCookieSettings: () => void;

  constructor(private ngbActiveModal: NgbActiveModal) {}

  public onCustomCookieSettings() {
    this.customCookieSettings();
    this.dismiss();
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public ok() {
    this.ngbActiveModal.close();
  }
}
