<app-modal-v2>
  <div class="modal-xs" app-modal-content>
    <app-modal-content>
      <div class="modal-header justify-content-center">
        <div class="row w-100">
          <div class="col p-0 modal-header-text">
            <h4>
              {{'social-login-confirmation.register.header.text' | translate}}
            </h4>
          </div>
          <div class="col-1 p-0 d-flex justify-content-end">
            <div class="icon icon--close" (click)="close()"></div>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <p class="modal-text">
          {{'social-login-confirmation.register.body.text' | translate}}
          <a [href]="dataProtectionLink" target="_blank"
            >{{'social-login-confirmation.register.link.label' | translate}}</a
          >
        </p>

        <app-button [type]="type" (clickEvent)="continue()"
          >{{'social-login-confirmation.register.button.text' |
          translate}}</app-button
        >
      </div>
    </app-modal-content>
  </div>
</app-modal-v2>
