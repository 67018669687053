<div class="gallery__container">
  <gallery
    *ngIf="(galleryConfig$ | async); let config"
    [items]="items"
    [thumbWidth]="config.thumbWidth"
    [thumbHeight]="config.thumbHeight"
    [thumbPosition]="config.thumbPosition"
    [thumb]="config.thumb"
    [dots]="config.dots"
    [ngStyle]="{'background-color': 'transparent'}"
    (indexChange)="changeBackgroundImage($event)"
  >
    <div
      *galleryBoxDef="let state = state; let config = config"
      [ngStyle]="styleObject"
    ></div>
  </gallery>
</div>
