<div class="profile__content">
  <form [formGroup]="form" class="profile__subform">
    <div
      class="form__section card"
      appTenantTooltip
      [appTooltipAnchor]="'profile-dk-zero.name-form'"
      [appTooltipUseMobileStrategy]="true"
    >
      <div class="card-body d-flex row ml0 mr0">
        <div class="col-12 col-md-6 mb20">
          <app-form-field>
            <app-form-field-label
              >{{'general.first_name_l' | translate}}</app-form-field-label
            >
            <input
              appInput
              [placeholder]="'forms.type_name' | translate"
              [required]="true"
              formControlName="firstname"
              class="form-control"
            />
          </app-form-field>
        </div>
        <div class="col-12 col-md-6 mb20">
          <app-form-field>
            <app-form-field-label
              >{{'general.last_name_l' | translate}}</app-form-field-label
            >
            <input
              appInput
              [placeholder]="'forms.type_name' | translate"
              [required]="true"
              formControlName="name"
              class="form-control"
            />
          </app-form-field>
        </div>
      </div>
    </div>
    <div>
      <div class="uppercase-m semibold search-profile-section-header">
        {{ 'general.wbs' | translate }}
      </div>

      <div class="form__section card">
        <div class="card-body">
          <div class="mb16 wbs2-select" formGroupName="housingPermission">
            <app-form-field>
              <app-form-field-label>
                {{ 'general.housing_permission_type_l' | translate }}
              </app-form-field-label>
              <app-single-select-dropdown-stepper
                formControlName="type"
                [items]="WBS_STEPPER_OPTIONS"
                [dropdownTitle]="'tenant_profile.wbs_dropdown_title_m' | translate"
                [placeholderText]="'tenant_profile.wbs_dropdown_placeholder_l' | translate"
              >
              </app-single-select-dropdown-stepper>
            </app-form-field>
          </div>

          <div *ngIf="housingPermissionTypeValue">
            <div class="row mb16" formGroupName="housingPermission">
              <div class="col-12">
                <app-form-field>
                  <app-form-field-label
                    >{{'general.housing_permission_amount_people_l' |
                    translate}}</app-form-field-label
                  >
                  <input
                    appInput
                    [placeholder]="'forms.type_housing_permission_amount_people' | translate"
                    formControlName="amountPeople"
                    class="form-control"
                    type="number"
                    min="0"
                  />
                </app-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <app-form-field>
                  <app-form-field-label
                    >{{ 'general.wbs_l' | translate }}</app-form-field-label
                  >
                  <app-attachments
                    [showDownload]="false"
                    [showRemove]="true"
                    [isDocument]="true"
                    [hideFileUpload]="false"
                    [multiple]="true"
                    [accept]="housingPermissionDocumentAcceptance.types"
                    [size]="housingPermissionDocumentAcceptance.size"
                    [subInformation]="'PDF / JPG / PNG ' + ('general.file_size_up_to_l' | translate) + ' 20 MB'"
                    formControlName="wbsDocument"
                  ></app-attachments>
                </app-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
