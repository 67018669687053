<app-cookie-banner
  class="cookie-banner"
  [cookiePreference]="cookiesPreference"
  (acceptAll)="acceptCookies($event)"
  (customSettings)="customCookieSettings($event)"
></app-cookie-banner>

<div class="new-contract">
  <p class="new-contract__subtitle uppercase-m semibold">
    {{'digital_contract.wizard_sign.title_l' | translate}}
  </p>

  <div
    *ngIf="hasContact"
    class="new-contract__wizard-help d-flex"
    (click)="openHelp()"
  >
    <svg-icon
      src="/assets/images/icons/icon-dmv-questionmark.svg"
      [svgStyle]="{'height.px':20, 'width.px': 20}"
    ></svg-icon>
    <span class="pl5">{{'general.need_help_l' | translate}}</span>
  </div>

  <app-wizard
    [steps]="allSteps"
    [currentStepNumber]="currentStepIndex"
    [currentStepValid]="currentForm?.valid"
    [isProcessing]="(processing$ | async) ||
                              (showStep('signing') && currentStepIndex === getWizardStepIndex('signing') && !isRedirectedFromDocuSign && (isErrorSendingTenantData$ | async) !== true)"
    [hideButtonContainer]="currentStepIndex === getWizardStepIndex('qes') || currentStepIndex === getWizardStepIndex('qesMissingTermsAndConditions')"
  >
    <ng-container *ngIf="showStep('personalInformation')">
      <app-contract-personal-information
        *appWizardStep="let change"
        [form]="personalInformationForm"
        [stepChange]="change"
        [steps]="allSteps"
        [stepIndex]="getStepIndex('personalInformation')"
        [contract]="contract"
        (onSendMessage)="sendMessageToLandlord()"
        (onNextStep)="nextStepAfterPersonalInfo($event)"
        (onPreviousStep)="previousStep()"
      ></app-contract-personal-information>
    </ng-container>

    <ng-container *ngIf="showStep('viewing')">
      <app-contract-viewing
        *appWizardStep="let change"
        [form]="viewingForm"
        [stepChange]="change"
        [steps]="allSteps"
        [stepIndex]="getStepIndex('viewing')"
        [continueSigning]="continueContractWhenNotVisitedFlat"
        [minViewingDate]="minDate"
        [maxViewingDate]="maxDate"
        [flatVisitedUpdated]="flatVisitedUpdated"
        [contract]="contract"
        (onNextStep)="nextStepAfterViewing($event)"
        (onPreviousStep)="previousStep()"
      ></app-contract-viewing>
    </ng-container>

    <ng-container *ngIf="showStep('qes')">
      <app-qualified-electronic-signature
        *appWizardStep="let change"
        [stepChange]="change"
        [steps]="allSteps"
        [stepIndex]="getStepIndex('qes')"
        [qesActionState]="qesActionState$ | async"
        [qesLink]="qesLink$ | async"
        (startQesCheck)="startQesCheck($event)"
      ></app-qualified-electronic-signature>
    </ng-container>

    <ng-container *ngIf="showStep('qesMissingTermsAndConditions')">
      <app-qualified-electroinic-signature-confirm-terms-and-conditions
        *appWizardStep="let change"
        [stepChange]="change"
        [steps]="allSteps"
        [stepIndex]="getStepIndex('qesMissingTermsAndConditions')"
      ></app-qualified-electroinic-signature-confirm-terms-and-conditions>
    </ng-container>

    <ng-container *ngIf="showStep('idVerification')">
      <app-contract-id-verification
        *appWizardStep="let change"
        [form]="idVerificationForm"
        [stepChange]="change"
        [steps]="allSteps"
        [stepIndex]="getStepIndex('idVerification')"
        (onPreviousStep)="previousStep()"
      ></app-contract-id-verification>
    </ng-container>

    <ng-container *ngIf="showStep('codeVerification')">
      <app-contract-code-verification
        *appWizardStep="let change"
        [form]="codeVerificationForm"
        [numberOfDigits]="VERIFICATION_CODE_LENGTH"
        [iban]="getIban"
        [stepChange]="change"
        [steps]="allSteps"
        [stepIndex]="getStepIndex('codeVerification')"
      ></app-contract-code-verification>
    </ng-container>

    <ng-container *ngIf="showStep('signing')">
      <app-contract-signing
        *appWizardStep="let change"
        [form]="signingForm"
        [stepChange]="change"
        [steps]="allSteps"
        [stepIndex]="getStepIndex('signing')"
        [isSigned]="isRedirectedFromDocuSign"
        [hasError]="isErrorSendingTenantData$ | async"
        [showShopCard]="showShopCard"
        (onComplete)="completeSigning()"
        (onNextStep)="nextStep($event)"
      ></app-contract-signing>
    </ng-container>
  </app-wizard>
</div>
