<div class="profile__content">
  <p class="content__sub-title uppercase-m semibold">
    {{ 'general.step' | translate }} {{ stepIndex + 1 }}
  </p>
  <h2 class="content__title title-xl">{{ currentStep.nameNice | translate}}</h2>
  <div
    class="dk-info__container"
    appTenantTooltip
    [appTooltipAnchor]="'profile-dk-explanation'"
    [appTooltipUseMobileStrategy]="true"
  >
    <app-badge [color]="currentStep.dkColor" [icon]="'lock-closed'" class="pr5">
      {{'general.data_privacy_l' | translate}}
    </app-badge>
    <app-badge [color]="'neutral-light-dark'">{{ label | translate}}</app-badge>
    <p class="mt10 mb0">{{ description | translate}}</p>
  </div>
  <hr class="mb25 mt25" />
</div>
