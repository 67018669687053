<div class="property-info">
  <div class="property-info__scrollable-content">
    <div class="property-banner__container">
      <app-property-banner
        [applicantStatus]="item?.status"
      ></app-property-banner>
    </div>

    <div class="property-details">
      <div class="property-main-info" (click)="onShowDetails()">
        <div class="property-image__container">
          <app-image
            [defaultSrc]=" picture ? (picture | appImageSize:'S') : '/assets/images/object-image-placeholder.svg' "
            alt="{{ item?.property?.data?.name  }}"
          ></app-image>
        </div>

        <div class="property-name">
          <span
            *ngIf="item?.property?.externalId"
            class="property-item__externalId"
          >
            {{'property.external_id_l' | translate}}
            {{item?.property?.externalId}}
          </span>
          {{ item?.property?.data?.name }}
        </div>

        <div class="property-address" *ngIf="showAddress">
          {{ item?.property?.data?.address | appAddress }}
        </div>
        <div class="property-price">
          {{ item?.property?.data?.basePrice | currency:'EUR' }} / {{
          'general.base_rent_l' | translate }}
        </div>
      </div>

      <div class="property-subdetails">
        <div class="property-subdetail align-items-center">
          <svg-icon
            src="/assets/images/icons/icon-area.svg"
            [applyClass]="true"
            [svgStyle]="{'width.px': 15}"
            class="secondary-svg-color rect line polyline path"
          ></svg-icon>
          {{ item?.property?.data?.size | number }} m<sup>2</sup>
        </div>

        <div *ngIf="isFlat" class="property-subdetail flex-column">
          <div *ngIf="item?.property?.data?.rooms">
            <svg-icon
              src="/assets/images/icons/icon-rooms.svg"
              [applyClass]="true"
              [svgStyle]="{'width.px': 15}"
              class="secondary-svg-color rect line polyline path"
            ></svg-icon>
            {{ item?.property?.data?.rooms | number:'1.0':'de' }} {{
            (!item?.property?.data?.halfRooms ? 'general.rooms_l' :
            item?.property?.data?.rooms === 1 ? 'general.whole_room_l' :
            'general.whole_rooms_l') | translate}}
          </div>

          <div *ngIf="item?.property?.data?.halfRooms">
            <svg-icon
              src="/assets/images/icons/icon-half-rooms.svg"
              [applyClass]="true"
              [svgStyle]="{'width.px': 15}"
              class="secondary-svg-color line polyline path"
            ></svg-icon>
            {{ item?.property?.data?.halfRooms | number:'1.0':'de' }} {{
            (item?.property?.data?.halfRooms === 1 ? 'general.half_room_l' :
            'general.half_rooms_l') | translate}}
          </div>
        </div>
      </div>

      <div class="customer-info__container">
        <app-customer-info-legacy
          [name]="item?.property?.customer?.name"
          [logo]="item?.property?.customer?.logo"
        ></app-customer-info-legacy>
      </div>

      <div *ngIf="item?.property?.wbs" class="wbs-container">
        <app-badge class="mr5" [color]="'white'">
          {{ 'property.wbs_badge_l' | translate }}
        </app-badge>
      </div>
    </div>
    <div
      class="participated-agents__container pl12 pr12 pb12"
      *ngIf="participatedAgents"
    >
      {{'messenger.participated_agents_l' | translate}}
      <div
        class="participated-agents pt5"
        *ngFor="let participatedAgent of participatedAgents"
      >
        <div class="participated-agents__item d-flex" *ngIf="participatedAgent">
          <app-avatar
            class="col p0"
            size="medium"
            [name]="participatedAgent | appFullName"
            [imgSrc]="$any(participatedAgent?.portrait)"
          ></app-avatar>
          <div class="col-10 p0 pl12 d-flex">
            <p class="m-auto ml0">{{participatedAgent | appFullName}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="blocked_chat m-auto mb10" *ngIf="blockedChat">
      {{ 'messenger.blocked_chat_l' | translate }}
    </div>
    <div class="deleted_chat m-auto mb10" *ngIf="isDeleted">
      {{ 'messenger.application_deleted_l' | translate }}
    </div>
  </div>
  <div class="property-actions" [class.blocked_chat]="blockedChat">
    <div class="action-button__container">
      <app-property-action-button
        *ngIf="status !== applicantStatus.REJECTED && isNotBlocked"
        [item]="item"
        [disabled]="isProcessing"
        [profileComplete]="profileComplete"
        (apply)="onApply()"
        (editProfile)="onEditProfile()"
        (viewInvitation)="onViewInvitation()"
        (noFittingAppointment)="onNoFittingAppointment()"
        (viewAppointment)="onViewAppointment()"
        (setIntent)="onSetIntent($event)"
      ></app-property-action-button>
    </div>

    <div class="action-menu__container">
      <app-context-menu>
        <div menu-content>
          <app-context-menu-item
            *ngIf="item?.hasQuestions"
            (clickEvent)="onAnswerCustomQuestion()"
            [iconLeft]="'question'"
          >
            {{ 'property.edit_custom_questions_a' | translate }}
          </app-context-menu-item>

          <app-context-menu-item
            *ngIf="isNoIntent"
            (clickEvent)="onSetIntent(applicantStatus.INTENT)"
            [iconLeft]="'check-framed'"
          >
            {{ 'property.declare_intent_a' | translate }}
          </app-context-menu-item>

          <app-context-menu-item
            *ngIf="isIntent"
            (clickEvent)="onSetIntent(applicantStatus.NO_INTENT)"
            [iconLeft]="'remove'"
            [iconSpacing]="true"
          >
            {{ 'property.declare_no_intent_a' | translate }}
          </app-context-menu-item>

          <hr *ngIf="isNoIntent || isIntent" />

          <!-- TODO: Add info icon, iconSpacing remove -->
          <app-context-menu-item
            (clickEvent)="onShowDetails()"
            [iconLeft]="'context-menu-outlined'"
            [iconSpacing]="true"
          >
            {{ 'property.show_details_a' | translate }}
          </app-context-menu-item>

          <app-context-menu-item
            (clickEvent)="onShareProperty()"
            [iconLeft]="'share'"
          >
            {{ 'property.share_a' | translate }}
          </app-context-menu-item>

          <app-context-menu-item
            *ngIf="status === applicantStatus.ACCEPTED &&
               item?.status === applicantStatus.ATTENDING_TO_VIEWING"
            (clickEvent)="onExportToCalendar()"
            [iconLeft]="'calendar'"
          >
            {{ 'property.export_appointment_to_calendar_a' | translate }}
          </app-context-menu-item>

          <app-context-menu-item
            *ngIf="showExposeDownload"
            (clickEvent)="onExportPDF()"
            [iconLeft]="'pdf'"
          >
            {{ 'property.export_pdf_a' | translate }}
          </app-context-menu-item>

          <app-context-menu-item
            [disabled]="isProcessing"
            (clickEvent)="onRemoveProperty()"
            [iconLeft]="'delete'"
          >
            {{ (isProposal ? 'property.remove_property_proposal_a' :
            'property.remove_property_application_a') | translate }}
          </app-context-menu-item>

          <app-context-menu-item
            *ngIf="isShowSelfDisclosure && !isProposal && isNotBlocked"
            [disabled]="isProcessing"
            (clickEvent)="openSelfDisclosureModal()"
            [iconLeft]="'self-disclosure'"
            [iconSpacing]="true"
          >
            {{ 'self_disclosure.answers.open_dialog_menu_a' | translate }}
          </app-context-menu-item>

          <app-context-menu-item
            *ngIf="canChooseAnotherAppointment"
            (clickEvent)="onViewInvitation()"
            [iconLeft]="'calendar'"
          >
            {{ 'appointment.select_appointment_a' | translate }}
          </app-context-menu-item>
        </div>
      </app-context-menu>
    </div>
  </div>

  <div
    class="d-flex justify-content-center"
    *ngIf="status === applicantStatus.ACCEPTED && item?.upcomingAppointmentDate && hasAppointmentInTheFuture(item.upcomingAppointmentDate)"
  >
    <app-button
      class="col-12 mb-4 mt-2"
      [type]="'light-bordered'"
      [disabled]="isProcessing"
      (clickEvent)="onCancelViewing()"
    >
      {{ 'property.cancel_appointment_a' | translate }}
    </app-button>
  </div>
</div>
