import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DownloadService } from 'libs/infrastructure';
import { BaseStep } from 'libs/components/legacy/wizard/wizard-step/base-step';
import { PropertySearcherUser, SearchProfile } from '@ui/shared/models';

@Component({
  selector: 'app-profile-review',
  templateUrl: './profile-review.component.html',
  styleUrls: ['./profile-review.component.scss']
})
export class ProfileReviewComponent extends BaseStep implements OnInit {
  @Input() userData: PropertySearcherUser;
  @Input() guestMode = false;
  @Input() searchProfiles: SearchProfile[];
  @Input() schufaCard: boolean;
  @Output() editSearchProfile = new EventEmitter<SearchProfile>();
  @Output() deleteSearchProfile = new EventEmitter<string>();
  @Output() addSearchProfile = new EventEmitter();

  constructor(private downloadService: DownloadService) {
    super();
  }

  public onEditSearchProfile(searchProfile: SearchProfile) {
    this.editSearchProfile.emit(searchProfile);
  }

  public onDeleteSearchProfile(id: string) {
    this.deleteSearchProfile.emit(id);
  }

  public onAddSearchProfile() {
    this.addSearchProfile.emit();
  }
}
