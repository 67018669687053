<div *ngIf="hasIncompleteProfile" class="profile-completeness">
  <div class="profile-completeness__top">
    <div class="profile-completeness__info">
      <div class="profile-completeness__header">
        <div class="profile-completeness__header-title">
          {{ 'profile.user_profile_l' | translate }}
        </div>
        <div class="hint-container">
          <app-hint
            >{{ 'profile.profile_completeness_hint_m' | translate }}</app-hint
          >
        </div>
      </div>

      <div class="uppercase-s profile-completeness__percentage">
        {{ completeness }}% {{ 'profile.profile_complete_l' | translate }}
      </div>
    </div>
    <app-button [type]="'primary'" (clickEvent)="editProfile()">
      {{ 'profile.complete_profile_a' | translate }}
    </app-button>
  </div>

  <div class="profile-completeness__indicator">
    <div class="profile-completeness__bar"></div>
    <div
      class="profile-completeness__progress"
      [ngStyle]="{width: completeness + '%'}"
    ></div>
  </div>
</div>
