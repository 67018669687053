<div
  *ngIf="showContent && !propertyMatch?.upcomingAppointmentDate"
  [@fadeOnEnterLeave]
  class="overlay"
></div>

<div
  class="application-actions"
  [class.application-actions--embedded]="!isApplyPage"
  [class.show-content]="showContent"
  [class.upcoming-appointment]="propertyMatch?.upcomingAppointmentDate"
>
  <div class="application-actions__inner container pt0 pl0 pr0">
    <div class="application-actions__content col p0">
      <app-appointment-selection
        *ngIf="showChooseAppointment && (appointmentsActionState$ | async)?.done && !isApplyPage"
        [appointments]="appointments"
        [exclusiveAppointments]="exclusiveAppointments"
        [formControl]="newActiveAppointmentId"
      >
      </app-appointment-selection>
      <div
        *ngIf="propertyMatch?.upcomingAppointmentDate && !isApplyPage"
        class="application-actions__calendar"
      >
        <div class="calendar__time d-flex align-items-center">
          <div class="d-flex align-items-center">
            <svg-icon
              src="/assets/images/icons/calendar.svg"
              [applyClass]="true"
              [svgStyle]="{'width.px': 15}"
              class="secondary-svg-color path rect"
            ></svg-icon>
            <span class="pl5 pr5"
              >{{ propertyMatch?.upcomingAppointmentDate | appDateTime }}</span
            >
          </div>
          <div class="d-flex align-items-center">
            <svg-icon
              src="/assets/images/icons/icon-clock.svg"
              [applyClass]="true"
              [svgStyle]="{'width.px': 15}"
              class="secondary-svg-color path circle polyline"
            ></svg-icon>
            <span class="pl10"
              >{{ propertyMatch?.upcomingAppointmentDate | appDateTime: {
              withDate: false, withTime: true } }}</span
            >
          </div>
        </div>
      </div>
      <div
        class="property-expose__apply-anonymouse"
        *ngIf="showViaEmailForm && isApplyPage && (isCommercial(property) || isSales(property?.marketingType))"
      >
        <ng-container
          *ngIf="(applicationDone$ | async) !== true; else applicationDone"
        >
          <form [formGroup]="anonymousApplyForm">
            <div
              class="property-apply-anonymous__info"
              [innerHTML]="'property.apply_anonymous.info_m' | translate"
            ></div>
            <div class="mt8 row">
              <div class="col-6">
                <app-form-field>
                  <app-form-field-label
                    >{{'general.first_name_l' |
                    translate}}</app-form-field-label
                  >
                  <input
                    appInput
                    type="text"
                    [placeholder]="'forms.type_firstname' | translate"
                    formControlName="firstname"
                    class="form-control"
                  />
                </app-form-field>
              </div>
              <div class="col-6">
                <app-form-field>
                  <app-form-field-label
                    >{{'general.name_l' | translate}}</app-form-field-label
                  >
                  <input
                    appInput
                    type="text"
                    [placeholder]="'forms.type_name' | translate"
                    formControlName="name"
                    class="form-control"
                  />
                </app-form-field>
              </div>
            </div>
            <div class="mt8 mb8 row">
              <div class="col-6">
                <app-form-field>
                  <app-form-field-label
                    >{{'general.email_l' | translate}}</app-form-field-label
                  >
                  <input
                    appInput
                    type="text"
                    [placeholder]="'forms.type_email' | translate"
                    formControlName="email"
                    class="form-control"
                  />
                </app-form-field>
              </div>
              <div class="col-6">
                <app-form-field>
                  <app-form-field-label
                    >{{'general.phone_l' | translate}}</app-form-field-label
                  >
                  <input
                    appInput
                    type="text"
                    [placeholder]="'forms.type_phone' | translate"
                    formControlName="phone"
                    class="form-control"
                  />
                </app-form-field>
              </div>
            </div>
          </form>
        </ng-container>
        <ng-template #applicationDone>
          <div class="row justify-content-center">
            <img src="/assets/images/icons/icon-checkbox-green.svg" />
            <span
              class="property-apply-anonymous-success ml8"
              [innerHTML]="'property.apply_anonymous.success_m' | translate"
            ></span>
          </div>
          <div
            class="row justify-content-center mt12 mb20"
            [innerHTML]="'property.apply_anonymous.success_contact_m' | translate"
          ></div>
        </ng-template>
      </div>
    </div>
    <div class="application-actions__actions">
      <ng-container *ngIf="isApplyPage; else exposePage">
        <ng-container
          *ngIf="isSales(property?.marketingType); else showViaFormTemplate"
        >
          <app-button
            [disabled]="property?.rented || !salesLegalTextCheckboxesValid || (applyPageProcessing$ | async) || (salesGuestApplyDone$ | async)"
            (clickEvent)="onOpenSalesModal()"
            [type]="'primary'"
          >
            {{ (property?.rented ? 'property.not_available_a' :
            (property?.marketingType === 'SALES' ? 'property.apply_now_sales_a'
            : 'property.apply_now_a')) | translate }}
          </app-button>
        </ng-container>
        <ng-template #showViaFormTemplate>
          <ng-container
            *ngIf="!showViaEmailForm; else anonymousOrSalesApplyBtn"
          >
            <app-button
              [disabled]="disabled || property?.rented"
              (clickEvent)="onLandingApply()"
              [type]="'primary'"
            >
              {{ (property?.rented ? 'property.not_available_a' :
              'property.apply_now_a') | translate }}
            </app-button>
          </ng-container>
        </ng-template>
        <ng-template #anonymousOrSalesApplyBtn>
          <app-button
            (clickEvent)="onApplyAnonymous(anonymousApplyForm.value)"
            [disabled]="property?.rented || anonymousApplyForm.invalid || (applyPageProcessing$ | async)"
            [loading]="applyPageProcessing$ | async"
            [type]="'primary'"
          >
            {{ (property?.rented ? 'property.not_available_a' :
            'property.apply_anonymous_now_a') | translate }}
          </app-button>
        </ng-template>
      </ng-container>
      <ng-template #exposePage>
        <div class="row m-0">
          <app-button
            *ngIf="buttonType === applicantStatus.NEW_MATCH || buttonType === applicantStatus.AUTO_OFFERED || buttonType === applicantStatus.OFFERED"
            (clickEvent)="onRemoveProperty(propertyMatch)"
            [disabled]="disabled"
            class="col-6 pl0 pr5"
            [type]="'negative'"
          >
            {{ 'property.remove_property_proposal_a' | translate }}
          </app-button>

          <app-button
            *ngIf="buttonType === applicantStatus.NEW_MATCH || buttonType === applicantStatus.AUTO_OFFERED || buttonType === applicantStatus.OFFERED"
            (clickEvent)="onApply(propertyMatch)"
            [disabled]="disabled"
            class="col-6 pr0 pl5"
            [type]="'positive'"
          >
            {{ 'property.apply_now_a' | translate }}
          </app-button>
        </div>
        <div *ngIf="waitForLandlord">
          <app-button
            *ngIf="(profileComplete$ | async) !== true"
            [type]="'description'"
            [elevationHoverEffect]="false"
            [iconLeft]="'clock'"
            [disableHover]="true"
          >
            {{ 'properties.actions.waiting_for_landlord_l' | translate }}
          </app-button>
          <app-button
            *ngIf="(profileComplete$ | async)"
            [disabled]="disabled"
            (clickEvent)="editProfile()"
            [type]="'primary'"
            >{{ 'profile.edit_profile_a' | translate }}</app-button
          >
        </div>

        <ng-container *ngIf="buttonType === applicantStatus.NO_OPEN_SLOTS ">
          <app-button
            [type]="'description'"
            [elevationHoverEffect]="false"
            [disableHover]="true"
          >
            {{ 'properties.banner.no_open_slots_available_l' | translate }}
          </app-button>
        </ng-container>
        <ng-container
          *ngIf="buttonType === applicantStatus.INVITED_TO_VIEWING || buttonType === applicantStatus.ATTENDING_TO_VIEWING || buttonType === applicantStatus.ALL_APPOINTMENTS_DECLINED"
        >
          <ng-container
            *ngIf="(appointments?.length || exclusiveAppointments?.length); else noAppointmentsAvailable"
          >
            <div
              class="appointment__non-fitting"
              *ngIf="!showChooseAppointment && propertyMatch.status === applicantStatus.ALL_APPOINTMENTS_DECLINED && buttonType !== applicantStatus.DECLARE_INTENT"
            >
              <svg-icon
                src="/assets/images/icons/icon-no.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="white-svg-color path"
              ></svg-icon>
              <span class="pl10">
                {{ 'appointment.appointment_not_fitting' | translate }}
              </span>
            </div>
            <app-button
              *ngIf="(buttonType === applicantStatus.INVITED_TO_VIEWING || buttonType === applicantStatus.ALL_APPOINTMENTS_DECLINED) && !showChooseAppointment"
              (clickEvent)="showAppointments()"
              [disabled]="disabled"
              [type]="'primary'"
            >
              <ng-container
                *ngIf="buttonType !== applicantStatus.ALL_APPOINTMENTS_DECLINED;else nonFitting"
              >
                {{ 'property.view_invitation_a' | translate }}
              </ng-container>
              <ng-template #nonFitting>
                {{ 'appointment.change_decision_l' | translate }}
              </ng-template>
            </app-button>
            <app-button
              *ngIf="(buttonType === applicantStatus.INVITED_TO_VIEWING || buttonType === applicantStatus.ALL_APPOINTMENTS_DECLINED) && showChooseAppointment"
              (clickEvent)="onSelectAppointment()"
              [disabled]="!newActiveAppointmentId.value"
              [type]="'primary'"
            >
              <ng-container
                *ngIf="newActiveAppointmentId.value !== 'non_fitting';else nonFitting"
              >
                {{ 'property.select_appointment_a' | translate }}
              </ng-container>
              <ng-template #nonFitting>
                {{ 'appointment.non_fitting_selected_a_l' | translate }}
              </ng-template>
            </app-button>
          </ng-container>
        </ng-container>
        <app-button
          *ngIf="buttonType === applicantStatus.ATTENDING_TO_VIEWING"
          (clickEvent)="onCancelAppointment(activeAppointmentAcceptance.id)"
          [disabled]="(appointmentsItemActionState$ | async)?.pending"
          [type]="'negative'"
        >
          {{ 'appointment.cancel_viewing_a' | translate }}
        </app-button>
        <div
          class="row m0 intent-buttons__container"
          *ngIf="buttonType === applicantStatus.DECLARE_INTENT"
        >
          <app-intent-buttons
            [disabled]="disabled"
            (setIntent)="setIntent($event)"
          >
          </app-intent-buttons>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #noAppointmentsAvailable>
  <app-button
    *ngIf="!propertyMatch?.upcomingAppointmentDate"
    [type]="'description'"
    [elevationHoverEffect]="false"
    [disableHover]="true"
    [iconLeft]="'clock'"
  >
    {{ 'properties.actions.no_appointments_available_a' | translate }}
  </app-button>
</ng-template>
