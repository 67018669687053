import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-payment-positions-request-feedback-modal',
  templateUrl: './payment-positions-request-feedback-modal.component.html',
  styleUrls: ['./payment-positions-request-feedback-modal.component.scss']
})
export class PaymentPositionsRequestFeedbackModalComponent {
  constructor(private ngbActiveModal: NgbActiveModal) {}

  public onClose(alreadyPaid: boolean) {
    this.ngbActiveModal.close(alreadyPaid);
  }
}
