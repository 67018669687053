import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-get-schufa',
  templateUrl: './get-schufa.component.html',
  styleUrls: ['./get-schufa.component.scss']
})
export class GetSchufaComponent {
  @Output() goToSchufa = new EventEmitter();

  public onGoToSchufa() {
    this.goToSchufa.emit();
  }
}
