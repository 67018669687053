import { Component, OnInit } from '@angular/core';
import * as fromAppState from 'tenant-pool/+state';
import { filter, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  MovingChecklistStatus,
  MovingChecklistTimeSlot,
  MovingChecklistTimeSlotEnum,
  MovingChecklistTodo
} from '@ui/shared/models';
import { Store } from '@ngrx/store';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { MainPageNavigation } from 'tenant-pool/config';
import { isValueNullOrUndefined } from 'libs/utils';
import moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'app-moving-checklist-card',
  templateUrl: './moving-checklist-card.component.html',
  styleUrls: ['./moving-checklist-card.component.scss']
})
export class MovingChecklistCardComponent implements OnInit {
  public progress = 0;
  public moveInDate: string;
  public showNewMarker: boolean;
  public movingChecklist: MovingChecklistTimeSlot;
  public doNotAskForMoveInDate: boolean;
  public latestTodo: MovingChecklistTodo;
  private totalToDoCount = 0;
  private totalCheckedCount = 0;

  constructor(private store: Store<fromBaseState.BaseState>) {}

  public ngOnInit(): void {
    this.store
      .select(fromAppState.getGetByPropertyActionState)
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
        filter(state => state.done),
        switchMap(() => this.store.select(fromAppState.getMovingChecklist)),
        untilDestroyed(this)
      )
      .subscribe(movingChecklist => {
        this.movingChecklist = movingChecklist.checklist;
        this.moveInDate = movingChecklist.moveInDate;
        this.doNotAskForMoveInDate = movingChecklist.doNotAskForMoveInDate;
        this.totalToDoCount = 0;
        this.totalCheckedCount = 0;
        Object.entries(movingChecklist?.checklist).forEach(([_, checklist]) => {
          this.totalToDoCount += checklist.elements.length;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          checklist.elements.map(todo => {
            this.totalCheckedCount +=
              todo.status === MovingChecklistStatus.DONE ||
              todo.status === MovingChecklistStatus.NOT_NEEDED
                ? 1
                : 0;
          });
          this.progress = Math.round(
            (this.totalCheckedCount * 100) / this.totalToDoCount
          );
        });
        this.getLatestTodo();
      });
  }

  public getLatestTodo() {
    let days = 99999;
    Object.entries(this.movingChecklist).find(([key, checklist]) => {
      if (
        key !== MovingChecklistTimeSlotEnum.AFTER_ACCEPTANCE &&
        days > this.getDaysUntilTimeSlot(checklist.daysBeforeMoving) &&
        checklist.elements.some(i => i.status === MovingChecklistStatus.NOT_SET)
      ) {
        days = this.getDaysUntilTimeSlot(checklist.daysBeforeMoving);
        const todo = checklist.elements.find(
          i => i.status === MovingChecklistStatus.NOT_SET
        );
        this.latestTodo = {
          days,
          timeSlot: key,
          ...todo
        };
      }
    });
  }

  public getDaysUntilMove(): number {
    if (!this.doNotAskForMoveInDate) return;
    return moment(new Date(this.moveInDate).setHours(22, 59, 59, 0)).diff(
      moment(new Date().setHours(22, 59, 59, 0)),
      'days'
    );
  }

  public getDaysUntilTimeSlot(days: number): number {
    return this.getDaysUntilMove() - days;
  }

  public getNormalizedNumber(days: number): number {
    return days < 0 ? days * -1 : days;
  }

  public onGotoMovingChecklist(): void {
    if (isValueNullOrUndefined(this.doNotAskForMoveInDate)) {
      return this.store.dispatch(
        new fromBaseState.Go({
          path: [
            MainPageNavigation.SERVICE_CENTER,
            MainPageNavigation.MOVING_CHECKLIST_INTRO
          ]
        })
      );
    }
    let extras = {};

    if (this.latestTodo) {
      extras = {
        fragment: this.getIDName(this.latestTodo.type)
      };
    }

    this.store.dispatch(
      new fromBaseState.Go({
        path: [
          MainPageNavigation.SERVICE_CENTER,
          MainPageNavigation.MOVING_CHECKLIST
        ],
        extras
      })
    );
  }

  public getIDName(type: string): string {
    return type.replaceAll('_', '').toLowerCase();
  }
}
