import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { isNumber } from 'libs/utils';
import * as fromAppState from 'tenant-pool/+state';
import { MainPageNavigation } from 'tenant-pool/config';

@UntilDestroy()
@Component({
  selector: 'app-profile-completeness',
  templateUrl: './profile-completeness.component.html',
  styleUrls: ['./profile-completeness.component.scss']
})
export class ProfileCompletenessComponent implements OnInit {
  public completeness: number;

  constructor(private store: Store<fromBaseState.BaseState>) {}

  ngOnInit() {
    this.store
      .select(fromAppState.getProfileCompleteness)
      .pipe(untilDestroyed(this))
      .subscribe(completeness => (this.completeness = completeness));
  }

  get hasIncompleteProfile() {
    return isNumber(this.completeness) && this.completeness !== 100;
  }

  public editProfile() {
    this.store.dispatch(
      new fromBaseState.Go({
        path: [MainPageNavigation.PROFILE, 'edit', 'step']
      })
    );
  }
}
