import { Inject, Component, Output, EventEmitter, Input } from '@angular/core';
import { WINDOW_REF } from 'libs/infrastructure';

@Component({
  selector: 'app-moving-auction-card',
  templateUrl: './moving-auction-card.component.html',
  styleUrls: ['./moving-auction-card.component.scss']
})
export class MovingAuctionCardComponent {
  @Output() openMovingAuctionWizard = new EventEmitter();
  @Input() showNewMarker = false;
  constructor(@Inject(WINDOW_REF) private windowRef: Window) {}

  public onClickMovingAuction() {
    this.openMovingAuctionWizard.emit();
  }

  public onGoToInfo() {
    this.windowRef.open(
      'https://www.mieter.immomio.com/umzugsauktion',
      '_blank'
    );
  }
}
