import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Route } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { Observable } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';

import {
  RentDepositDeliveryOption,
  RentDepositFormData,
  RentDepositLandlordOption,
  RentDepositModel,
  RentDepositPaymentOption
} from '@ui/shared/models';
import { ModalService } from 'libs/components/legacy/modal';
import { GenderType, PropertyMatchBean } from '@ui/shared/models';
import * as fromBaseState from 'libs/infrastructure/base-state';

import * as fromAppState from 'tenant-pool/+state';
import * as fromRentDepositActions from 'tenant-pool/+state/rent-deposit/rent-deposit.actions';
import { dialogConfig, MainPageNavigation } from 'tenant-pool/config';
import { RentalDepositService } from 'tenant-pool/core';
import { RentDepositStatusModalComponent } from 'tenant-pool/screens/rent-deposit/rent-deposit-status-modal/rent-deposit-status-modal.component';
import { RentDepositStepRoutes } from 'tenant-pool/components/rent-deposit-wizard/rent-deposit-step.routes';
import { CurrencyPipe } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-rent-deposit-wizard',
  templateUrl: './rent-deposit-wizard.component.html',
  styleUrls: ['./rent-deposit-wizard.component.scss']
})
export class RentDepositWizardComponent implements OnInit, OnDestroy {
  @Input() formData: RentDepositFormData;
  @Input() propertyMatchBean: PropertyMatchBean;

  public allSteps: {
    name: string;
    nameNice: string;
  }[];
  public currentStep$: Observable<number>;
  public processing$: Observable<boolean>;
  public currentForm$: Observable<FormGroup>;

  public form: FormGroup;
  public rentDepositPayload: RentDepositModel;

  public genderTypes = [
    { value: GenderType.MALE, name: 'GENDERTYPES_MALE' },
    { value: GenderType.FEMALE, name: 'GENDERTYPES_FEMALE' },
    { value: GenderType.INTERSEXUAL, name: 'GENDERTYPES_INTERSEXUAL' }
  ];

  public paymentOptions = [
    {
      value: RentDepositPaymentOption.MONTHLY,
      name: 'rent_deposit.payment.option_monthly_l'
    },
    {
      value: RentDepositPaymentOption.YEARLY,
      name: 'rent_deposit.payment.option_yearly_l'
    }
  ];

  public deliveryOptions = [
    {
      value: RentDepositDeliveryOption.LANDLORD,
      name: 'rent_deposit.payment.delivery_option_landlord_l'
    },
    {
      value: RentDepositDeliveryOption.PROPERTY_SEARCHER,
      name: 'rent_deposit.payment.delivery_option_property_search_l'
    }
  ];

  public landlordOptions = [
    {
      value: RentDepositLandlordOption.LEGAL_PERSON,
      name: 'rent_deposit.object.landlord_legal_person_l'
    },
    {
      value: RentDepositLandlordOption.NATURAL_PERSON,
      name: 'rent_deposit.object.landlord_natural_person_l'
    }
  ];

  public get rentDepositInfo() {
    return this.propertyMatchBean?.rentDepositInfo;
  }

  constructor(
    private store: Store<fromAppState.AppState>,
    private fb: FormBuilder,
    private rentalDepositService: RentalDepositService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private currencyPipe: CurrencyPipe
  ) {}

  public ngOnInit(): void {
    this.processing$ = this.store
      .select(fromAppState.getRuvActionState)
      .pipe(map(state => state.pending));
    this.currentStep$ = this.store.select(fromAppState.getCurrentWizardStep);
    this.allSteps = this.getAllSteps();
    this.form = this.fb.group({
      personalInformation: [],
      object: [],
      payment: []
    });

    if (this.formData) {
      this.form.patchValue(this.formData);
    }

    this.currentForm$ = this.currentStep$.pipe(
      map(
        (currentStep: number) =>
          this.form.get(this.allSteps[currentStep - 1].name) as FormGroup
      )
    );

    this.paymentOptions = this.paymentOptions.map(item => {
      return {
        ...item,
        name: this.translateService.instant(item.name, {
          amount:
            item.value === RentDepositPaymentOption.MONTHLY
              ? this.currencyPipe.transform(
                  this.rentDepositInfo?.ruvData?.monthlyAmount,
                  'EUR'
                )
              : this.currencyPipe.transform(
                  this.rentDepositInfo?.ruvData?.yearlyAmount,
                  'EUR'
                )
        })
      };
    });

    this.store
      .select(fromAppState.getRuvActionState)
      .pipe(
        filter(state => state.done || !!state.error),
        withLatestFrom(this.store.select(fromAppState.getRentDepositInfo)),
        take(1)
      )
      .subscribe(([_, rentDepositInfo]) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        this.modalService.open<RentDepositStatusModalComponent>(
          RentDepositStatusModalComponent,
          {
            data: {
              rentDepositInfo,
              rentDepositModel: this.rentDepositPayload
            },
            backdrop: 'static'
          }
        );

        this.store.dispatch(fromAppState.RentDepositWizardReset());

        this.store.dispatch(
          new fromBaseState.Go({
            path: [MainPageNavigation.NEW_HOME]
          })
        );
      });
  }

  public ngOnDestroy(): void {
    this.store.dispatch(fromAppState.RentDepositWizardReset());
  }

  public nextStep() {
    this.rentDepositPayload = this.rentalDepositService.convertToPayload(
      this.form.value,
      this.rentDepositInfo
    );

    this.store.dispatch(
      fromRentDepositActions.RentDepositWizardNextStep({
        id: this.propertyMatchBean.id
      })
    );
  }

  public completeStep() {
    this.rentDepositPayload = this.rentalDepositService.convertToPayload(
      this.form.value,
      this.rentDepositInfo
    );
    const input = {
      propertyId: this.propertyMatchBean.property.id,
      input: {
        ...this.rentDepositPayload,
        contractData: {
          ...this.rentDepositPayload.contractData,
          isRentalContractUnlimited: true
        },
        bankData: {
          ...this.rentDepositPayload.bankData
        },
        deliveryData: {
          ...this.rentDepositPayload.deliveryData
        }
      }
    };
    this.store.dispatch(fromRentDepositActions.UseRuvDeposit(input));
  }

  public onPrivacyModal() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.modalService.openAcknowledgement({
      data: {
        ...dialogConfig.rentDeposit.ruvPrivacyAck,
        innerHTML: true
      }
    });
  }

  public onEdit(stepName: string) {
    const stepNumber =
      this.allSteps.findIndex(step => step.name === stepName) + 1;
    this.store.dispatch(
      fromRentDepositActions.RentDepositWizardGoToStep({
        stepNumber,
        id: this.propertyMatchBean.id
      })
    );
  }

  public previousStep() {
    this.store.dispatch(
      fromRentDepositActions.RentDepositWizardPreviousStep({
        id: this.propertyMatchBean.id
      })
    );
  }

  private getAllSteps() {
    return RentDepositStepRoutes.map((routeObject: Route) => ({
      name: routeObject.path,
      nameNice: routeObject.data.title
    }));
  }
}
