<app-modal-v2>
  <div class="modal-xs moving-auction">
    <app-modal-content>
      <div class="moving-auction__body">
        <ng-container [ngSwitch]="true">
          <ng-container *ngSwitchCase="isAccepted || isCreated">
            <div class="d-flex justify-content-center">
              <span class="moving-auction__headline moving-auction__body-text"
                >{{'moving_auction.status_modal.headline' | translate}}</span
              >
            </div>

            <div
              class="d-flex justify-content-center moving-auction__checkmark"
            >
              <svg-icon
                [src]="'/assets/images/icons/icon-check-circle-green.svg'"
                [applyClass]="true"
                [svgStyle]="{'height.px': 67, 'width.px': 67}"
                class="green-svg-color rect path"
              ></svg-icon>
            </div>

            <div class="d-flex justify-content-center">
              <span class="moving-auction__title"
                >{{'moving_auction.status_modal.title' | translate}}</span
              >
            </div>

            <div class="d-flex justify-content-center">
              <span
                class="moving-auction__body-text"
                [innerHTML]="'moving_auction.status_modal.body_text' | translate"
              ></span>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="hasError">
            <div class="d-flex justify-content-center">
              <span class="moving-auction__headline moving-auction__body-text"
                >{{'moving_auction.status_modal.headline_error' |
                translate}}</span
              >
            </div>

            <div
              class="d-flex justify-content-center moving-auction__checkmark"
            >
              <svg-icon
                [src]="'/assets/images/icons/icon-no.svg'"
                [applyClass]="true"
                [svgStyle]="{'height.px': 67, 'width.px': 67}"
                class="negative-svg-color rect path"
              ></svg-icon>
            </div>

            <div class="d-flex justify-content-center">
              <span class="moving-auction__title"
                >{{'moving_auction.status_modal.title_error' | translate}}</span
              >
            </div>

            <div class="d-flex justify-content-center">
              <span
                class="moving-auction__body-text"
                [innerHTML]="'moving_auction.status_modal.body_text_error' | translate"
              ></span>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <app-button (clickEvent)="close()" [type]="'primary'">
        {{ 'general.ok_a' | translate }}</app-button
      >
    </app-modal-footer>
  </div>
</app-modal-v2>
