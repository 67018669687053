<app-modal-v2>
  <div class="modal-m digital-help-modal">
    <app-modal-content>
      <div class="modal-body digital-help-modal__content">
        <div class="digital-help-modal__header">
          <p class="uppercase-m semibold digital-help-modal__title">
            {{ 'digital_contract.help_modal.header_l' | translate }}
          </p>
        </div>

        <div class="row digital-help-modal__body">
          <div class="col-12 mb8">
            <span class="semibold option-title mb16"
              >{{ 'digital_contract.help_modal.sub_title_l' | translate }}</span
            >
          </div>

          <div class="col-12" *ngIf="contact.lastname || contact.firstname">
            {{contact.firstname}} {{contact.lastname}}
          </div>

          <div class="col-12" *ngIf="contact.email">
            <a href="mailto:{{contact.email}}">{{contact.email}}</a>
          </div>

          <div class="col-12" *ngIf="contact.phone">
            <a href="tel:{{contact.phone}}">{{contact.phone}}</a>
          </div>
        </div>

        <div class="row digital-help-modal__body update-cookie-settings mt40">
          <div class="col-12 option-title">
            {{'account.update_cookie_settings_l' | translate}}
          </div>
          <div class="col-12 option-description">
            {{'account.update_cookie_settings_description_l' | translate}}
          </div>
          <div class="col-6 cookie-button">
            <app-button
              [type]="'primary'"
              (clickEvent)="onCustomCookieSettings()"
            >
              {{'account.update_cookie_settings_a' | translate}}
            </app-button>
          </div>
        </div>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer digital-help-modal__footer">
        <app-button (clickEvent)="ok()" [type]="'primary'">
          {{'general.ok' | translate}}</app-button
        >
      </div>
    </app-modal-footer>
  </div>
</app-modal-v2>
