import { Component, Input, EventEmitter, Output } from '@angular/core';
import {
  ApplicantStatus,
  PropertyApplicationStatus,
  PropertyMatchBean
} from '@ui/shared/models';

@Component({
  selector: 'app-property-action-button',
  templateUrl: './property-action-button.component.html',
  styleUrls: ['./property-action-button.component.scss']
})
export class PropertyActionButtonComponent {
  @Input() item: PropertyMatchBean;
  @Input() disabled: boolean;
  @Input() profileComplete: boolean;

  @Output() apply = new EventEmitter<PropertyMatchBean>();
  @Output() editProfile = new EventEmitter<void>();
  @Output() viewInvitation = new EventEmitter<PropertyMatchBean>();
  @Output() noFittingAppointment = new EventEmitter<void>();
  @Output() setIntent = new EventEmitter<PropertyApplicationStatus>();
  @Output() viewAppointment = new EventEmitter<PropertyMatchBean>();

  get buttonType() {
    return this.item?.status || ApplicantStatus.NEW_MATCH;
  }

  public onApply() {
    this.apply.emit(this.item);
  }

  public onEditProfile() {
    this.editProfile.emit();
  }

  public onViewInvitation() {
    this.viewInvitation.emit(this.item);
  }

  public onSetIntent(intent: PropertyApplicationStatus) {
    this.setIntent.emit(intent);
  }

  public onViewAppointment() {
    this.viewAppointment.emit(this.item);
  }

  public onNoFittingAppointment() {
    this.noFittingAppointment.emit();
  }
}
