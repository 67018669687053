import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemeService } from 'libs/infrastructure/theme/theme.service';
import { UrlUtilService } from 'tenant-pool/core';

@Component({
  selector: 'app-customer-info-legacy',
  templateUrl: './customer-info-legacy.component.html',
  styleUrls: ['./customer-info-legacy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerInfoLegacyComponent {
  @Input() name: string;
  @Input() logo: string;
  @Input() logoRedirectUrl: string;
  @Input() nameHeading: string;
  @Input() showOfferFrom = true;
  @Input() showInCooperationWith = true;

  public get showInCooperationWithImmomio() {
    return this.logo;
  }

  public get customerLogo() {
    return (
      this.logo ||
      this.themeService?.getTheme(this.themeService?.getDefaultTheme)?.logo?.url
    );
  }

  public get getLogoRedirectUrl() {
    return this.urlHelperService.getLogoRedirectUrl(this.logoRedirectUrl);
  }

  constructor(
    private themeService: ThemeService,
    private urlHelperService: UrlUtilService
  ) {}
}
