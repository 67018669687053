import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentPosition } from '@ui/shared/models';

@Component({
  selector: 'app-payment-positions-request-already-paid-modal',
  templateUrl: './payment-positions-request-already-paid-modal.component.html',
  styleUrls: ['./payment-positions-request-already-paid-modal.component.scss']
})
export class PaymentPositionsRequestAlreadyPaidModalComponent {
  public selectedPositions: PaymentPosition[];
  public currency: string;

  constructor(private ngbActiveModal: NgbActiveModal) {}

  public onClose() {
    this.ngbActiveModal.close();
  }

  public onDismiss() {
    this.ngbActiveModal.dismiss();
  }
}
