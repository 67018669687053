import { Directive, Input, ViewContainerRef, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as tooltipState from 'tenant-pool/+state/tooltip';
import { BaseState } from 'libs/infrastructure/base-state';
import { TooltipDirective } from 'libs/components/molecules/tooltip/tooltip.directive';

@UntilDestroy()
@Directive({
  selector: '[appTenantTooltip]'
})
export class TenantTooltipDirective extends TooltipDirective implements OnInit {
  @Input() appTooltipAnchor: string;
  @Input() appTooltipUseMobileStrategy = false;

  constructor(
    public viewContainerRef: ViewContainerRef,
    private store: Store<BaseState>
  ) {
    super(viewContainerRef);
  }

  ngOnInit() {
    super.ngOnInit();

    this.store
      .select(tooltipState.getTooltipProgress)
      .pipe(untilDestroyed(this))
      .subscribe(
        progress => (this.componentRef.instance.tooltipProgress = progress)
      );

    this.componentRef.instance.continueEvent
      .pipe(untilDestroyed(this))
      .subscribe(tooltip => {
        this.store.dispatch(
          tooltipState.setTooltipsSeen({ ids: [tooltip.id] })
        );
        this.store.dispatch(tooltipState.continueTooltipProgress());
      });

    this.componentRef.instance.closeEvent
      .pipe(untilDestroyed(this))
      .subscribe(tooltipTour => {
        this.store.dispatch(tooltipState.setTooltipTourSeen({ tooltipTour }));
        this.store.dispatch(tooltipState.resetTooltipProgress());
      });
  }
}
