import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Appointment,
  AppointmentAcceptanceShortInfo,
  AppointmentInvitations,
  AppointmentsBundles,
  AppointmentPropertyShortInfo,
  AppointmentInvitationState,
  PropertyApplicationStatus
} from '@ui/shared/models';

@Component({
  selector: 'app-appointments-property-item',
  templateUrl: './appointments-property-item.component.html',
  styleUrls: ['./appointments-property-item.component.scss']
})
export class AppointmentsPropertyItemComponent implements OnInit {
  @Input() applicationId: string;
  @Input() applicationStatus: string;
  @Input() property: AppointmentPropertyShortInfo;
  @Input() appointments: Appointment[];
  @Input() appointmentAcceptances: AppointmentAcceptanceShortInfo[];
  @Input() appointmentInvitations: AppointmentInvitations[];
  @Input() appointmentsItemLoading = false;
  @Input() showCustomerInfo = true;
  @Output() selectAppointment = new EventEmitter<AppointmentsBundles>();
  @Output() cancelAppointment = new EventEmitter<string>();
  @Output() exportToCalendar = new EventEmitter<{
    property: AppointmentPropertyShortInfo;
    appointment: Appointment;
  }>();

  public activeAppointment: Appointment;
  public activeAppointmentAcceptance: AppointmentAcceptanceShortInfo;

  public appointmentState = AppointmentInvitationState;

  get picture() {
    return this.property && this.property.image;
  }

  get appointmentsNotDeclined() {
    return (
      this.applicationStatus !==
      PropertyApplicationStatus.ALL_APPOINTMENTS_DECLINED
    );
  }

  public getActiveAppointment(): Appointment {
    if (this.appointmentAcceptances?.length === 0) return;
    this.activeAppointment = this.appointments
      .filter(
        appointment => appointment.state === AppointmentInvitationState.ACTIVE
      )
      .find(appointment => {
        this.activeAppointmentAcceptance = this.appointmentAcceptances
          .filter(
            appointmentAcceptances =>
              appointmentAcceptances.state === AppointmentInvitationState.ACTIVE
          )
          .find(
            appointmentAcceptances =>
              appointmentAcceptances.appointmentId === appointment.id
          );
        return !!this.activeAppointmentAcceptance;
      });
  }

  ngOnInit(): void {
    this.getActiveAppointment();
  }

  public onExportToCalendar() {
    if (!this.activeAppointment) return;
    this.exportToCalendar.emit({
      property: this.property as any,
      appointment: this.activeAppointment
    });
  }

  public onSelectAppointment() {
    this.selectAppointment.emit({
      applicationId: this.applicationId,
      applicationStatus: this.applicationStatus,
      property: this.property,
      appointments: this.appointments,
      appointmentAcceptances: this.appointmentAcceptances,
      appointmentInvitations: this.appointmentInvitations
    });
  }

  public onCancelViewing() {
    if (!this.activeAppointmentAcceptance) return;
    this.cancelAppointment.emit(this.activeAppointmentAcceptance.id);
  }
}
